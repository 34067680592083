import React from 'react';
import ErrorBoundary from './error-boundary';
import _ from 'lodash';
import { createBrowserHistory as createHistory } from 'history';
import { Route, Switch, Router } from 'react-router-dom';
import NavFrameV2 from '../NavFrameV2';
import { FlexRow, FlexColumn } from '../layouts';
import urlUtils from '../../utility/url';
import { copyObject } from '../../utility';
import equal from 'deep-equal';

// Create history object
const history = createHistory();

const TOPBAR_HEIGHT = 74;
const SIDEBAR_WIDTH_CLOSED = 50;
const SIDEBAR_WIDTH_OPEN = 225;
const BOTTOMBAR_HEIGHT = 0;
const MAIN_MIN_WIDTH = 1375;
const MAIN_MAX_WIDTH = 1800;

const defaultParamMap = {
  index: '',
  activeNavId: '',
  activeStepperDepth: 0,
  drawerOpen: false,
};

export default class DynamicAppV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      components: {},
      topbarHeight: TOPBAR_HEIGHT,
      sidebarWidth: SIDEBAR_WIDTH_OPEN,
      mainWidth: Math.min(
        Math.max(window.innerWidth - SIDEBAR_WIDTH_OPEN, MAIN_MIN_WIDTH),
        MAIN_MAX_WIDTH
      ),
      sidebarHeight: window.innerHeight,
      mainHeight: window.innerHeight - TOPBAR_HEIGHT - BOTTOMBAR_HEIGHT,
      drawerOpen: false,
    };

    this.unlisten = history.listen((location, action) => {
      this.handleHistoryChange(location, action);
    });
  }

  handleHistoryChange(location, action) {
    if (action === 'POP') {
      const url = history.location.pathname;
      const params = urlUtils.getURLParams(this.props.appConfig, url);
      this.props.setNav(params);
    }
  }

  updateDimensions() {
    const updateWidth =
      window.innerWidth - (this.state.drawerOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED);
    const updateHeight = window.innerHeight - BOTTOMBAR_HEIGHT;
    const updateMainHeight = window.innerHeight - BOTTOMBAR_HEIGHT - TOPBAR_HEIGHT;
    this.setState({
      mainWidth: Math.min(Math.max(updateWidth, MAIN_MIN_WIDTH), MAIN_MAX_WIDTH),
      sidebarHeight: updateHeight,
      mainHeight: updateMainHeight,
    });

    this.props.setDimensions({
      topbarHeight: TOPBAR_HEIGHT,
      sidebarWidth: this.state.drawerOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED,
      mainWidth: Math.min(Math.max(updateWidth, MAIN_MIN_WIDTH), MAIN_MAX_WIDTH),
      sidebarHeight: updateHeight,
      mainHeight: updateMainHeight,
    });
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    this.checkNav(this.props);
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    if (!prevProps.dimensions || _.isEmpty(prevProps.dimensions)) {
      this.updateDimensions();
    }

    const url = window.location.pathname;

    const params = urlUtils.getURLParams(this.props.appConfig, url);

    if (!equal(prevProps.nav, this.props.nav) || params.index !== this.props.nav.index) {
      this.checkNav(prevProps);
    }
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    this.unlisten();
  }

  renderRoutes() {
    let arr = [];

    this.props.appConfig.routes.forEach((route) => {
      arr.push(
        <Route exact path={route.route} component={route.component} key={`route-${route.index}`} />
      );
    });

    return (
      <Router history={history}>
        <Switch>{arr}</Switch>
      </Router>
    );
  }

  render() {
    const updateSoftware =
      this.props.softwareVersion &&
      this.props.buildIdentifier !== this.props.softwareVersion &&
      this.props.nav.index !== 'login';
    return (
      <div>
        <FlexColumn fullWidth>
          <ErrorBoundary dimensions={this.props.dimensions}>
            <FlexRow fullWidth>
              <NavFrameV2
                height={this.state.sidebarHeight}
                history={history}
                nav={this.props.nav}
                updates={this.props.updates}
                navConfig={this.props.appConfig}
                alerts={this.props.alerts}
                setNav={this.props.setNav}
                hideAlert={this.props.hideAlert}
                logoutUser={this.props.logoutUser}
                user={this.props.auth}
                onChange={this.handleDrawer.bind(this)}
                mainTitle={this.props.appConfig.mainTitle}
                activeNavId={this.props.nav.activeNavId}
                activeStepperDepth={this.props.nav.activeStepperDepth}
                setSearch={this.handleSetSearch.bind(this)}
                drawerOpen={this.props.nav.drawerOpen}
                saving={this.props.appState.saving}
                subTitle={this.props.subTitle}
                buildIdentifier={this.props.buildIdentifier}
                updateSoftwareVersion={updateSoftware}
                goToAdmin={this.props.isProjectsAdmin ? this.props.goToAdmin : () => {}}
                goToSettings={this.props.goToSettings}
                isProjectsAdmin={this.props.isProjectsAdmin}
              >
                <FlexColumn>{this.renderRoutes()}</FlexColumn>
              </NavFrameV2>
            </FlexRow>
          </ErrorBoundary>
        </FlexColumn>
      </div>
    );
  }

  handleDrawer(opened) {
    this.setState({ drawerOpen: opened }, () => {
      this.updateDimensions();
    });
  }

  handleSetSearch(term) {
    this.props.setSearch(term);
  }

  checkNav(props) {
    const { nav, appConfig } = props;

    if (!_.isEmpty(nav)) {
      let map = this.updateParamMap(nav);
      const url = urlUtils.makeURL(appConfig, nav, map);
      if (history.location.pathname !== url) {
        history.push(url);
      }
    } else {
      const url = window.location.pathname;

      const params = urlUtils.getURLParams(appConfig, url);
      this.props.setNav(params);
    }
  }

  updateParamMap(nav) {
    let map = copyObject(defaultParamMap);
    for (let key in nav) {
      if (nav.hasOwnProperty(key)) {
        map[key] = nav[key];
      }
    }

    return map;
  }
}
