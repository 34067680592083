export const gnbColorPalette = {
  dark: {
    primaryLight: '#5E5E66',
    primaryMedium: '#2D2D34',
    primaryDark: '#212126',
    secondaryLight: '#7FCBD3',
    secondaryMedium: '#0097A7',
    secondaryDark: '#005A64',
    surfaceLight: '#5E5E66',
    surfaceMedium: '#3b3b43',
    surfaceDark: '#323239',
    background: '#3B3B43',
    backgroundGradient: 'linear-gradient(-225deg, #212126 0%, #2D2D34 59%)',
    textLight: '#FFFFFF',
    textMedium: '#AFAFB2',
    textDark: '#87878C',
    chart1Light: '#FCCE54',
    chart1Dark: '#FFAC12',
    chart2Light: '#FF7F57',
    chart2Dark: '#FF521C',
    chart3Light: '#72B7E8',
    chart3Dark: '#0044A7',
    chart4Light: '#8468A9',
    chart4Dark: '#663897',
    messageSuccess: '#1EB980',
    messageError: '#E24233',
    chartYellow: '#fcd879',
    chartRed: '#e24233',
    chartGrey: '#747781',
    chartBackgroundBlack: '#34353d',
    chartGreyText: '#afafb2',
    purple: '#be98e6',
    orange: '#ff8d57',
    blue: '#72b7e8',
    red: '#e24233',
    darkYellow: '#ffac12',
    lightGreen: '#82c77f',
    green: '#1eb980',
    yellow: '#fcd879',
    darkRed: '#b50000',
    darkGreen: '#007d51',
    darkBlue: '#0044a7',
    darkOrange: '#ff651c',
    darkPurple: '#663897',
    white: '#ffffff',
    lightGrey: '#afafb2',
    mediumGrey: '#87878c',
    dimGrey: '#707173',
    teal: '#34353d',
    level2: '#222328',
    level3: '#2b2c33',
    level4: '#34353d',
    level5: '#76777a',
    level6: '#a7a7a9',
    level7: '#747781',
    level8: '#a6a8ae',
    primary50:'#E5FFFF',
    primary100: '#B3FFFF',
    primary200: '#7FFFFF',
    primary300: '#4DE4F4',
    primary400: '#1AB1C1',
    primary500: '#0097A7',
    primary600: '#007E8E',
    primary700: '#006474',
    primary800: '#004B5B',
    surface: '#222328',
    reportBackground: '#f7f7f7',
    seaFoam: '#01afaa',
    black: '#000000',
  },
};

export const gnbDarkTheme = {

  typography: {
    useNextVariants: true,
    fontFamily: ['Heebo', 'sans-serif'],
    h1: {
      fontWeight: 300,
      fontSize: 96,
      letterSpacing: -1.63,
    },
    h2: {
      fontWeight: 300,
      fontSize: 60,
      letterSpacing: -0.54,
    },
    h3: {
      fontWeight: 400,
      fontSize: 42,
    },
    h4: {
      fontSize: 34,
      lineHeight: 0.71,
      fontWeight: 400,
      letterSpacing: 0.25,
    },
    h5: {
      fontWeight: 500,
      fontSize: 24,
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: 0.25,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: 0.5,
      color: gnbColorPalette.dark.textLight,
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0.25,
      color: gnbColorPalette.dark.textLight,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.1,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 1.0,
      lineHeight: 1.14,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: 0.44,
      lineHeight: 1.33,
      color: gnbColorPalette.dark.textMedium,
    },
  },
  palette: {
    type: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: gnbColorPalette.dark.primaryDark,
      default: gnbColorPalette.dark.background,
    },
    primary: {
      light: gnbColorPalette.dark.secondaryLight,
      main: gnbColorPalette.dark.secondaryMedium,
      dark: gnbColorPalette.dark.secondaryDark,
    },
    secondary: {
      main: gnbColorPalette.dark.primaryMedium,
    },
    error: {
      main: gnbColorPalette.dark.messageError,
    },
    text: {
      secondary: gnbColorPalette.dark.textMedium,
      primary: gnbColorPalette.dark.textLight,
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: gnbColorPalette.dark.primaryDark,
      },
    },
    MuiIcon: {
      colorPrimary: gnbColorPalette.dark.textLight,
      colorSecondary: gnbColorPalette.dark.secondaryMedium,
    },
    MuiSvgIcon: {
      colorPrimary:
          {
            color: gnbColorPalette.dark.textLight,
          },
      colorSecondary: {
        color: gnbColorPalette.dark.secondaryMedium,
      },
    },
    MuiTableCell: {
      body: {
        fontSize: 16,
      },
      head: {
        fontSize: 16,
        color: gnbColorPalette.dark.textMedium,
      },
    },
    MuiButton: {
      contained: {
        color: gnbColorPalette.dark.textMedium,
        backgroundColor: gnbColorPalette.dark.primaryMedium,
      },
      textSecondary: {
        color: gnbColorPalette.dark.textLight,
      },
      containedSecondary: {
        backgroundColor: gnbColorPalette.dark.primaryMedium,
      },
      outlined: {
        border: `1px solid ${gnbColorPalette.dark.secondaryMedium}`,
      },

    },
    MuiSelect: {
      // see https://github.com/mui-org/material-ui/issues/9826
      select: {
        padding: undefined,
        //paddingRight: theme.spacing(4),
        height: undefined,
      },
      selectMenu: {
        lineHeight: undefined,
      },
    },
    appVar: {
      backgroundColor: gnbColorPalette.dark.surfaceMedium,
    },
  },

};
