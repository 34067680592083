import { select } from "redux-saga/effects";
import * as actions from "../actions";
import {
  getRoleDefinitions,
  getCompartments,
  getGroupDefinitions,
} from "../reducers/selectors";
import { findObject } from "../utility";
import { makeAPICall } from "./api-builder";
import { isEmpty } from "../shared-components/src/utility";

const PORTAL_URL =
  process.env.REACT_APP_PORTAL_SERVICE ||
  process.env.REACT_APP_PORTAL_URL ||
  "http://localhost:3101";

// ********************** API **********************************
export function* api_authenticate(auth) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/authenticate",
    auth,
    actions.RECEIVED_AUTHENTICATE,
    false,
    true
  );
}

export function* api_getUsers() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/users.json",
    {},
    actions.RECEIVED_GET_USERS
  );
}

export function* api_createUser(user) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/users.json",
    user,
    // yield fixupUserDataForUpdate(Object.assign({}, user)),
    actions.RECEIVED_CREATE_USER
  );
}

export function* api_updateUser(user) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/users/${user.id}.json`,
    //yield fixupUserDataForUpdate(Object.assign({}, user)),
    user,
    actions.RECEIVED_UPDATE_USER
  );
}

export function* api_deleteUser(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/users/${id}.json`,
    {},
    actions.RECEIVED_DELETE_USER
  );
}

export function* api_getGroupDefinitions() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/group-definitions.json",
    {},
    actions.RECEIVED_GET_GROUP_DEFINITIONS
  );
}

export function* api_createGroupDefinition(group) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/group-definitions.json",
    yield fixupGroupDataForUpdate(Object.assign({}, group)),
    actions.RECEIVED_CREATE_GROUP_DEFINITION
  );
}

export function* api_updateGroupDefinition(group) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/group-definitions/${group.id}.json`,
    yield fixupGroupDataForUpdate(Object.assign({}, group)),
    actions.RECEIVED_UPDATE_GROUP_DEFINITION
  );
}

export function* api_deleteGroupDefinition(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/group-definitions/${id}.json`,
    {},
    actions.RECEIVED_DELETE_GROUP_DEFINITION
  );
}

export function* api_getCompartments() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/compartments.json",
    {},
    actions.RECEIVED_GET_COMPARTMENTS
  );
}

export function* api_createCompartment(compartment) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/compartments.json",
    yield fixupCompartmentDataForUpdate(Object.assign({}, compartment)),
    actions.RECEIVED_CREATE_COMPARTMENT
  );
}

export function* api_updateCompartment(compartment) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/compartments/${compartment.id}.json`,
    yield fixupCompartmentDataForUpdate(Object.assign({}, compartment)),
    actions.RECEIVED_UPDATE_COMPARTMENT
  );
}

export function* api_deleteCompartment(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/compartments/${id}.json`,
    {},
    actions.RECEIVED_DELETE_COMPARTMENT
  );
}

export function* api_getAssets() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/assets.json",
    {},
    actions.RECEIVED_GET_ASSETS
  );
}

export function* api_createAsset(asset) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/assets.json",
    asset,
    actions.RECEIVED_CREATE_ASSET
  );
}

export function* api_updateAsset(asset) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/assets/${asset.id}.json`,
    asset,
    actions.RECEIVED_UPDATE_ASSET
  );
}

export function* api_deleteAsset(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/assets/${id}.json`,
    {},
    actions.RECEIVED_DELETE_ASSET
  );
}

export function* api_getRoleDefinitions() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/role-definitions.json",
    {},
    actions.RECEIVED_GET_ROLE_DEFINITIONS
  );
}

export function* api_createRoleDefinition(roleDefinition) {
  let _roleDefinition = Object.assign({}, roleDefinition);
  _roleDefinition.policies_attributes = roleDefinition.policies;
  delete _roleDefinition.policies;
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/role-definitions.json",
    _roleDefinition,
    actions.RECEIVED_CREATE_ROLE_DEFINITION
  );
}

export function* api_updateRoleDefinition(roleDefinition) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/role-definitions/${roleDefinition.id}.json`,
    yield fixupRoleDataForUpdate(Object.assign({}, roleDefinition)),
    actions.RECEIVED_UPDATE_ROLE_DEFINITION
  );
}

export function* api_deleteRoleDefinition(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/role-definitions/${id}.json`,
    {},
    actions.RECEIVED_DELETE_ROLE_DEFINITION
  );
}

export function* api_getPolicyDefinitions() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/policy-definitions.json",
    {},
    actions.RECEIVED_GET_POLICY_DEFINITIONS
  );
}

export function* api_getCompartmentFlagDefinitions() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/compartment-flag-definitions.json",
    {},
    actions.RECEIVED_GET_COMPARTMENT_FLAG_DEFINITIONS
  );
}

export function* api_getAccounts() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/accounts.json",
    {},
    actions.RECEIVED_GET_ACCOUNTS
  );
}

export function* api_createAccount(account) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/accounts.json",
    account,
    actions.RECEIVED_CREATE_ACCOUNT
  );
}

export function* api_updateAccount(account) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/accounts/${account.id}.json`,
    account,
    actions.RECEIVED_UPDATE_ACCOUNT
  );
}

export function* api_deleteAccount(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/accounts/${id}.json`,
    {},
    actions.RECEIVED_DELETE_ACCOUNT
  );
}

export function* api_createAddress(address) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/addresses.json",
    address,
    actions.RECEIVED_CREATE_ADDRESS
  );
}

export function* api_updateAddress(address) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/addresses/${address.id}.json`,
    address,
    actions.RECEIVED_UPDATE_ADDRESS
  );
}

export function* api_deleteAddress(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/addresses/${id}.json`,
    {},
    actions.RECEIVED_DELETE_ADDRESS
  );
}

export function* api_createLocation(location) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/locations.json",
    location,
    actions.RECEIVED_CREATE_LOCATION
  );
}

export function* api_updateLocation(location) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/locations/${location.id}.json`,
    location,
    actions.RECEIVED_UPDATE_LOCATION
  );
}

export function* api_deleteLocation(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/locations/${id}.json`,
    {},
    actions.RECEIVED_DELETE_LOCATION
  );
}

export function* api_createContact(contact) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/contacts.json",
    contact,
    actions.RECEIVED_CREATE_CONTACT
  );
}

export function* api_updateContact(contact) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/contacts/${contact.id}.json`,
    contact,
    actions.RECEIVED_UPDATE_CONTACT
  );
}

export function* api_deleteContact(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/contacts/${id}.json`,
    {},
    actions.RECEIVED_DELETE_CONTACT
  );
}

export function* api_createAccountUser(accountUser) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/account-users.json",
    accountUser,
    actions.RECEIVED_CREATE_ACCOUNT_USER
  );
}

export function* api_deleteAccountUser(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/account-users/${id}.json`,
    {},
    actions.RECEIVED_DELETE_ACCOUNT_USER
  );
}

export function* api_createGroup(group) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/groups.json",
    group,
    actions.RECEIVED_CREATE_GROUP
  );
}

export function* api_deleteGroup(id) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/groups/${id}.json`,
    {},
    actions.RECEIVED_DELETE_GROUP
  );
}

export function* api_getUserConfig() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/user-configs.json",
    {},
    actions.RECEIVED_GET_USER_CONFIG
  );
}

export function* api_getPolicyDocument() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/policy-documents.json",
    {},
    actions.RECEIVED_GET_POLICY_DOCUMENT
  );
}

export function* api_updateUserProfileImage(id, image) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    `/service/user-profile-images/${id}`,
    { profile_image: image },
    actions.RECEIVED_UPDATE_USER_PROFILE_IMAGE
  );
}

export function* api_moveAssetList(ids, destinationId) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/assets/move-list",
    { ids: ids, destination_id: destinationId },
    actions.RECEIVED_MOVE_ASSET_LIST
  );
}

function* fixupGroupDataForUpdate(group) {
  let newRoles = [];

  const groups = yield select(getGroupDefinitions);

  let prevGroupData = group.id
    ? Object.assign({}, findObject(groups, group.id))
    : null;

  // Add the results
  group.roles.forEach((role) => {
    let found = false;
    if (prevGroupData) {
      prevGroupData.roles.forEach((r) => {
        if (r.role_definition_id === role.role_definition_id) {
          found = true;
        }
      });
    }

    if (!found) {
      newRoles.push({
        role_definition_id: role.role_definition_id,
        group_definition_id: role.group_definition_id,
      });
    }
  });

  if (prevGroupData) {
    // Did we delete something?
    prevGroupData.roles.forEach((role) => {
      let found = false;

      group.roles.forEach((r) => {
        if (r.role_definition_id === role.role_definition_id) {
          found = true;
        }
      });

      if (!found) {
        // Mark for delete
        newRoles.push({ id: role.id, _destroy: 1 });
      }
    });
  }

  group.roles_attributes = newRoles;
  delete group.roles;

  let newAccounts = [];

  group.accounts.forEach((account) => {
    let found = false;
    if (prevGroupData) {
      prevGroupData.accounts.forEach((a) => {
        if (a.account_id === account.account_id) {
          found = true;
        }
      });
    }

    if (!found) {
      newAccounts.push({
        account_id: account.account_id,
        group_definition_id: group.id,
        include_children: account.include_children,
      });
    }
  });

  if (prevGroupData) {
    // Did we delete something?
    prevGroupData.accounts.forEach((account) => {
      let found = false;

      group.accounts.forEach((a) => {
        if (a.account_id === account.account_id) {
          found = true;
        }
      });

      if (!found) {
        // Mark for delete
        newAccounts.push({ id: account.id, _destroy: 1 });
      }
    });
  }

  group.group_accounts_attributes = newAccounts;
  delete group.accounts;

  return group;
}

function* fixupRoleDataForUpdate(role) {
  let newPolicyDefinitions = [];

  const roles = yield select(getRoleDefinitions);

  let prevPolicyData = Object.assign({}, findObject(roles, role.id));

  // Add the results
  role.policies.forEach((policy) => {
    let found = false;
    prevPolicyData.policies.forEach((p) => {
      if (p.policy_definition_id === policy.policy_definition_id) {
        found = true;
      }
    });

    if (!found) {
      newPolicyDefinitions.push({
        policy_definition_id: policy.policy_definition_id,
        role_definition_id: role.id,
      });
    }
  });

  // Did we delete something?
  prevPolicyData.policies.forEach((policy) => {
    let found = false;

    role.policies.forEach((p) => {
      if (p.id === policy.id) {
        found = true;
      }
    });

    if (!found) {
      // Mark for delete
      newPolicyDefinitions.push({ id: policy.id, _destroy: 1 });
    }
  });

  role.policies_attributes = newPolicyDefinitions;
  delete role.policies;
  return role;
}

function* fixupCompartmentDataForUpdate(compartment) {
  let newFlags = [];

  const compartments = yield select(getCompartments);

  let prevCompartmentData = Object.assign(
    {},
    findObject(compartments, compartment.id)
  );

  // Add the results
  compartment.compartment_flags.forEach((flag) => {
    let found = false;
    if (!isEmpty(prevCompartmentData)) {
      prevCompartmentData.compartment_flags.forEach((r) => {
        if (
          r.compartment_flag_definition_id ===
          flag.compartment_flag_definition_id
        ) {
          found = true;
        }
      });
    }

    if (!found) {
      newFlags.push({
        compartment_flag_definition_id: flag.compartment_flag_definition_id,
        compartment_id: compartment.id,
      });
    }
  });

  // Did we delete something?
  if (!isEmpty(prevCompartmentData)) {
    prevCompartmentData.compartment_flags.forEach((flag) => {
      let found = false;

      compartment.compartment_flags.forEach((r) => {
        if (
          r.compartment_flag_definition_id ===
          flag.compartment_flag_definition_id
        ) {
          found = true;
        }
      });

      if (!found) {
        // Mark for delete
        newFlags.push({ id: flag.id, _destroy: 1 });
      }
    });
  }

  compartment.compartment_flags_attributes = newFlags;
  delete compartment.compartment_flags;
  return compartment;
}
