import {
  RECEIVED_GET_GROUP_DEFINITIONS,
  RECEIVED_CREATE_GROUP_DEFINITION,
  RECEIVED_UPDATE_GROUP_DEFINITION,
  DELETE_GROUP_DEFINITION
} from '../actions';

import {copyObjectArray} from "../utility";

export default function (state = [], action) {
  let copy = copyObjectArray(state);
  switch(action.type) {
    case RECEIVED_GET_GROUP_DEFINITIONS:
      return action.payload;

    case RECEIVED_CREATE_GROUP_DEFINITION:
      return addGroup(copy, action.payload);

    case RECEIVED_UPDATE_GROUP_DEFINITION:
      return updateGroup(copy, action.payload);

    case DELETE_GROUP_DEFINITION:
      return removeGroup(copy, action.payload);

    default:
      return state;
  }
}

function addGroup(state, group) {
  state.push(group);
  return state;
}

function updateGroup(state, updatedGroup) {
  for(let i = 0; i < state.length; i++) {
    if(state[i].id === updatedGroup.id) {
      state[i] = updatedGroup;
      break;
    }
  }

  return state;
}

function removeGroup(state, id) {
  for(let i = 0; i < state.length; i++) {
    if(state[i].id === id) {
      state.splice(i,1);
      break;
    }
  }

  return state;
}