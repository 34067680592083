import {
  SHOW_ALERT,
  HIDE_ALERT
} from '../actions';


const initialState = {
  show: false,
  msg: '',
  type: 'info',
};
export default function (state = initialState, action) {

  switch (action.type) {
    case SHOW_ALERT:
      return {
        show: true,
        msg: action.payload.msg,
        type: action.payload.type
      };

    case HIDE_ALERT:
      return initialState;
    default:
      return state;
  }
}
