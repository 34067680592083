import {
  RECEIVED_GET_POLICY_DOCUMENT
} from '../actions';

export default function (state = {}, action) {

  switch(action.type) {
    case RECEIVED_GET_POLICY_DOCUMENT:
      return action.payload;

    default:
      return state;
  }
}