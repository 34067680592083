import * as React from 'react';
import {FlexCenter} from '../layouts';
import {strings} from './localization';

const LoadingComponent = ({ isLoading, error }) => {
  if (isLoading) {
    return (
      <div/>
    );
  }else if (error) {
    console.log(error);
    return (
        <FlexCenter style={{width: '100%', height: '100%'}}>
          {strings.problemLoadingPage}
        </FlexCenter>
    );
  }else {
    return null;
  }
};

export default LoadingComponent;