export const getAuth = (state) => state.authenticate;
export const getUsers = (state) => state.users;
export const getActiveUser = (state) => state.activeUser;
export const getGroupDefinitions = (state) => state.groupDefinitions;
export const getActiveGroupDefinition = (state) => state.activeGroupDefinition;
export const getCompartments = (state) => state.compartments;
export const getActiveCompartment = (state) => state.activeCompartment;
export const getAssets = (state) => state.assets;
export const getActiveAsset = (state) => state.activeAsset;
export const getRoleDefinitions = (state) => state.roleDefinitions;
export const getPolicyDefinitions = (state) => state.policyDefinitions;
export const getCompartmentFlagDefinitions = (state) => state.compartmentFlagDefinitions;
export const getAccounts = (state) => state.accounts;
export const getActiveAccount = (state) => state.activeAccount;
export const getActiveAddress = (state) => state.activeAddress;
export const getActiveLocation = (state) => state.activeLocation;
export const getActiveContact = (state) => state.activeContact;
export const getUserConfig = (state) => state.userConfig;
export const getPolicyDocument = (state) => state.policyDocument;
export const getUpdates = (state) => state.updates;