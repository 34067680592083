import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { strings } from './localization';

const BuildUpdate = (_props) => {
  return (
    <Dialog
      open={true}
      //onClose={handleClose}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{strings.buildUpdateNotification}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {strings.buildUpdateText}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default BuildUpdate;
