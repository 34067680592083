import * as Loadable from 'react-loadable';
import LoadingComponent from './loading-component';

export const AsyncComponent = (loader) => {
  return Loadable({
    loader: loader,
    loading: LoadingComponent
  });
};

