// State Actions
export const AUTHENTICATE = 'authenticate';
export const SET_REMEMBER = 'set_remember';

export const GET_USERS = 'get_users';
export const CREATE_USER = 'create_user';
export const UPDATE_USER = 'update_user';
export const DELETE_USER = 'delete_user';

export const SET_ACTIVE_USER = 'set_active_user';

export const GET_GROUP_DEFINITIONS = 'get_group_definitions';
export const CREATE_GROUP_DEFINITION = 'create_group_definition';
export const UPDATE_GROUP_DEFINITION = 'update_group_definition';
export const DELETE_GROUP_DEFINITION = 'delete_group_definition';

export const CREATE_GROUP = 'create_group';
export const DELETE_GROUP = 'delete_group';

export const SET_ACTIVE_GROUP_DEFINITION = 'set_active_group_definition';

export const LOGOUT_USER = 'logout_user';

export const GET_COMPARTMENTS = 'get_compartments';
export const CREATE_COMPARTMENT = 'create_compartment';
export const UPDATE_COMPARTMENT = 'update_compartment';
export const DELETE_COMPARTMENT = 'delete_compartment';

export const GET_COMPARTMENT_FLAG_DEFINITIONS = 'get_compartment_flag_definitions';

export const SET_ACTIVE_COMPARTMENT = 'set_active_compartment';

export const GET_ASSETS = 'get_assets';
export const CREATE_ASSET = 'create_asset';
export const UPDATE_ASSET = 'update_asset';
export const DELETE_ASSET = 'delete_asset';
export const MOVE_ASSET_LIST = 'move_asset_list';

export const SET_ACTIVE_ASSET = 'set_active_asset';

export const GET_ROLE_DEFINITIONS = 'get_role_definitions';
export const CREATE_ROLE_DEFINITION = 'create_role_definition';
export const UPDATE_ROLE_DEFINITION = 'update_role_definition';
export const DELETE_ROLE_DEFINITION = 'delete_role_definition';

export const SET_ACTIVE_ROLE_DEFINITION = 'set_active_role_definition';

export const GET_POLICY_DEFINITIONS = 'get_policy_definitions';

export const SET_ACTIVE_ACCOUNT = 'set_active_account';

export const GET_ACCOUNTS = 'get_accounts';
export const CREATE_ACCOUNT = 'create_account';
export const UPDATE_ACCOUNT = 'update_account';
export const DELETE_ACCOUNT = 'delete_account';

export const CREATE_ADDRESS = 'create_address';
export const UPDATE_ADDRESS = 'update_address';
export const DELETE_ADDRESS = 'delete_address';

export const SET_ACTIVE_ADDRESS = 'set_active_address';

export const CREATE_LOCATION = 'create_location';
export const UPDATE_LOCATION = 'update_location';
export const DELETE_LOCATION = 'delete_location';

export const SET_ACTIVE_LOCATION = 'set_active_location';

export const CREATE_CONTACT = 'create_contact';
export const UPDATE_CONTACT = 'update_contact';
export const DELETE_CONTACT = 'delete_contact';

export const SET_ACTIVE_CONTACT = 'set_active_contact';

export const CREATE_ACCOUNT_USER = 'create_account_user';
export const DELETE_ACCOUNT_USER = 'delete_account_user';

export const SET_NAV = 'set_nav';

export const SET_ACCOUNT_TREE = 'set_compartment_tree';

export const SET_DIMENSIONS = 'set_dimensions';

export const SHOW_ALERT = 'show_alert';
export const HIDE_ALERT = 'hide_alert';

// State Manipulation Actions
export const RECEIVED_AUTHENTICATE = 'received_authenticate';

export const RECEIVED_GET_USERS = 'received_get_users';
export const RECEIVED_CREATE_USER = 'received_create_user';
export const RECEIVED_UPDATE_USER = 'received_update_user';
export const RECEIVED_DELETE_USER = 'received_delete_user';

export const RECEIVED_GET_GROUP_DEFINITIONS = 'received_get_group_definitions';
export const RECEIVED_CREATE_GROUP_DEFINITION = 'received_create_group_definition';
export const RECEIVED_UPDATE_GROUP_DEFINITION = 'received_update_group_definition';
export const RECEIVED_DELETE_GROUP_DEFINITION = 'received_delete_group_definition';

export const RECEIVED_CREATE_GROUP = 'received_create_group';
export const RECEIVED_DELETE_GROUP = 'received_delete_group';

export const RECEIVED_GET_COMPARTMENTS = 'received_get_compartments';
export const RECEIVED_CREATE_COMPARTMENT = 'received_create_compartment';
export const RECEIVED_UPDATE_COMPARTMENT = 'received_update_compartment';
export const RECEIVED_DELETE_COMPARTMENT = 'received_delete_compartment';

export const RECEIVED_GET_COMPARTMENT_FLAG_DEFINITIONS = 'received_get_compartment_flag_definitions';

export const RECEIVED_GET_ASSETS = 'received_get_assets';
export const RECEIVED_CREATE_ASSET = 'received_create_asset';
export const RECEIVED_UPDATE_ASSET = 'received_update_asset';
export const RECEIVED_DELETE_ASSET = 'received_delete_asset';
export const RECEIVED_MOVE_ASSET_LIST = 'received_move_asset_list';

export const RECEIVED_GET_ROLE_DEFINITIONS = 'received_get_role_definitions';
export const RECEIVED_CREATE_ROLE_DEFINITION = 'received_create_role_definition';
export const RECEIVED_UPDATE_ROLE_DEFINITION = 'received_update_role_definition';
export const RECEIVED_DELETE_ROLE_DEFINITION = 'received_delete_role_definition';

export const RECEIVED_GET_POLICY_DEFINITIONS = 'received_get_policy_definitions';

export const RECEIVED_GET_ACCOUNTS = 'received_get_accounts';
export const RECEIVED_CREATE_ACCOUNT = 'received_create_account';
export const RECEIVED_UPDATE_ACCOUNT = 'received_update_account';
export const RECEIVED_DELETE_ACCOUNT = 'received_delete_account';

export const RECEIVED_CREATE_ADDRESS = 'received_create_address';
export const RECEIVED_UPDATE_ADDRESS = 'received_update_address';
export const RECEIVED_DELETE_ADDRESS = 'received_delete_address';

export const RECEIVED_CREATE_LOCATION = 'received_create_location';
export const RECEIVED_UPDATE_LOCATION = 'received_update_location';
export const RECEIVED_DELETE_LOCATION = 'received_delete_location';

export const RECEIVED_CREATE_CONTACT = 'received_create_contact';
export const RECEIVED_UPDATE_CONTACT = 'received_update_contact';
export const RECEIVED_DELETE_CONTACT = 'received_delete_contact';

export const RECEIVED_CREATE_ACCOUNT_USER = 'received_create_account_user';
export const RECEIVED_DELETE_ACCOUNT_USER = 'received_delete_account_user';

export const SET_APP_STATE = 'set_app_state';
export const SET_DATA_REQUESTED = 'set_data_requested';
export const SET_DATA_RECEIVED = 'set_data_received';
export const CLEAR_DATA_REQUESTED = 'clear_data_requested';
export const CLEAR_ALL_UPDATES = 'clear_all_updates';

export const GET_USER_CONFIG = 'get_user_config';
export const RECEIVED_GET_USER_CONFIG = 'received_get_user_config';

export const UPDATE_USER_PROFILE_IMAGE = 'update_user_profile_image';
export const RECEIVED_UPDATE_USER_PROFILE_IMAGE = 'received_update_user_profile_image';

export const GET_POLICY_DOCUMENT = 'get_policy_document';
export const RECEIVED_GET_POLICY_DOCUMENT = 'received_get_policy_document';

// Action Helper
function action(type, payload = {}) {
  return {type, payload}
}

// State Action Functions
export const authenticate = (auth) => action(AUTHENTICATE, auth);
export const remember = ( bRemember ) => action(SET_REMEMBER, bRemember)

export const getUsers = () => action(GET_USERS, {});
export const createUser = (user) => action(CREATE_USER, user);
export const updateUser = (user) => action(UPDATE_USER, user);
export const deleteUser = (id) => action(DELETE_USER, id);

export const setActiveUser = (user) => action(SET_ACTIVE_USER, user);

export const getGroupDefinitions = () => action(GET_GROUP_DEFINITIONS, {});
export const createGroupDefinition = (group) => action(CREATE_GROUP_DEFINITION, group);
export const updateGroupDefinition = (group) => action(UPDATE_GROUP_DEFINITION, group);
export const deleteGroupDefinition = (id) => action(DELETE_GROUP_DEFINITION, id);

export const createGroup = (group) => action(CREATE_GROUP, group);
export const deleteGroup = (id) => action(DELETE_GROUP, id);

export const setActiveGroupDefinition = (group) => action(SET_ACTIVE_GROUP_DEFINITION, group);

export const logoutUser = () => action(LOGOUT_USER, {});

export const getAssets = () => action(GET_ASSETS, {});
export const createAsset = (asset) => action(CREATE_ASSET, asset);
export const updateAsset = (asset) => action(UPDATE_ASSET, asset);
export const deleteAsset = (id) => action(DELETE_ASSET, id);
export const moveAssetList = (ids, destinationId) => action(MOVE_ASSET_LIST, {ids, destinationId});

export const setActiveAsset = (asset) => action(SET_ACTIVE_ASSET, asset);

export const getRoleDefinitions = () => action(GET_ROLE_DEFINITIONS, {});
export const createRoleDefinition = (roleDefinition) => action(CREATE_ROLE_DEFINITION, roleDefinition);
export const updateRoleDefinition = (roleDefinition) => action(UPDATE_ROLE_DEFINITION, roleDefinition);
export const deleteRoleDefinition = (id) => action(DELETE_ROLE_DEFINITION, id);

export const setActiveRoleDefinition = (roleDefinition) => action(SET_ACTIVE_ROLE_DEFINITION, roleDefinition);

export const getPolicyDefinitions = () => action(GET_POLICY_DEFINITIONS, {});

export const getAccounts = () => action(GET_ACCOUNTS, {});
export const createAccount = (account) => action(CREATE_ACCOUNT, account);
export const updateAccount = (account) => action(UPDATE_ACCOUNT, account);
export const deleteAccount = (id) => action(DELETE_ACCOUNT, id);

export const setActiveAccount = (account) => action(SET_ACTIVE_ACCOUNT, account);

export const createAddress = (address) => action(CREATE_ADDRESS, address);
export const updateAddress = (address) => action(UPDATE_ADDRESS, address);
export const deleteAddress = (id) => action(DELETE_ADDRESS, id);

export const setActiveAddress = (address) => action(SET_ACTIVE_ADDRESS, address);

export const createLocation = (location) => action(CREATE_LOCATION, location);
export const updateLocation = (location) => action(UPDATE_LOCATION, location);
export const deleteLocation = (id) => action(DELETE_LOCATION, id);

export const setActiveLocation = (location) => action(SET_ACTIVE_LOCATION, location);

export const createContact = (contact) => action(CREATE_CONTACT, contact);
export const updateContact = (contact) => action(UPDATE_CONTACT, contact);
export const deleteContact = (id) => action(DELETE_CONTACT, id);

export const setActiveContact = (contact) => action(SET_ACTIVE_CONTACT, contact);

export const createAccountUser = (accountUser) => action(CREATE_ACCOUNT_USER, accountUser);
export const deleteAccountUser = (id) => action(DELETE_ACCOUNT_USER, id);

export const getPolicyDocument = () => action(GET_POLICY_DOCUMENT, {});

export const setNav = (nav) => action(SET_NAV, nav);

export const setDimensions = (dimensions) => action(SET_DIMENSIONS, dimensions);

export const showAlert = (alert) => action(SHOW_ALERT, alert);
export const hideAlert = () => action(HIDE_ALERT, {});

export const getUserConfig = () => action(GET_USER_CONFIG, {});

export const updateUserProfileImage = (id, image) => action(UPDATE_USER_PROFILE_IMAGE, {id, image})

