import {
  RECEIVED_GET_ASSETS,
  RECEIVED_CREATE_ASSET,
  RECEIVED_UPDATE_ASSET,
  DELETE_ASSET,
  RECEIVED_MOVE_ASSET_LIST,
} from '../actions';

import {copyObjectArray} from "../utility";

export default function (state = [], action) {
  let copy = copyObjectArray(state);
  switch (action.type) {
    case RECEIVED_GET_ASSETS:
      return action.payload;

    case RECEIVED_CREATE_ASSET:
      return addAsset(copy, action.payload);

    case RECEIVED_UPDATE_ASSET:
      return updateAsset(copy, action.payload);

    case DELETE_ASSET:
      return removeAsset(copy, action.payload);

    case RECEIVED_MOVE_ASSET_LIST:
      return updateAssetList(copy, action.payload)

    default:
      return state;
  }
}

function addAsset(state, user) {
  state.push(user);
  return state;
}

function updateAsset(state, updatedAsset) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === updatedAsset.id) {
      state[i] = updatedAsset;
      break;
    }
  }

  return state;
}

function removeAsset(state, id) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === id) {
      state.splice(i, 1);
      break;
    }
  }

  return state;
}

function updateAssetList(state, updatedAssets) {
  updatedAssets.forEach(asset => {
    updateAsset(state, asset);
  });

  return state;
}