import {
  SET_DIMENSIONS
} from '../actions';

export default function (state = {}, action) {

  switch(action.type) {
    case SET_DIMENSIONS:
      return action.payload;

    default:
      return state;
  }
}
