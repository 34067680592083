import {
  SET_ACCOUNT_TREE
} from '../actions';

export default function (state = [], action) {

  switch(action.type) {
    case SET_ACCOUNT_TREE:
      return action.payload;

    default:
      return state;
  }
}
