import {
  RECEIVED_CREATE_ROLE_DEFINITION,
  RECEIVED_GET_ROLE_DEFINITIONS,
  RECEIVED_UPDATE_ROLE_DEFINITION,
  DELETE_ROLE_DEFINITION
} from '../actions';
import {copyObject} from "../shared-components/src/utility";

export default function (state = [], action) {

  switch(action.type) {
    case RECEIVED_GET_ROLE_DEFINITIONS:
      return action.payload;

    case RECEIVED_CREATE_ROLE_DEFINITION:
      return addRoleDefinition(copyObject(state), action.payload);

    case RECEIVED_UPDATE_ROLE_DEFINITION:
      return updateRoleDefinition(copyObject(state), action.payload);

    case DELETE_ROLE_DEFINITION:
      return removeRoleDefinition(copyObject(state), action.payload);
      
    default:
      return state;
  }
}

function addRoleDefinition(state, role) {
  state.push(role);
  return state;
}

function updateRoleDefinition(state, updatedRoleDefinition) {
  for(let i = 0; i < state.length; i++) {
    if(state[i].id === updatedRoleDefinition.id) {
      state[i] = updatedRoleDefinition;
      break;
    }
  }

  return state;
}

function removeRoleDefinition(state, id) {
  for(let i = 0; i < state.length; i++) {
    if(state[i].id === id) {
      state.splice(i,1);
      break;
    }
  }

  return state;
}
