import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    other: 'Other',

    facility: 'Facility',
    corporate: 'Corporate',

    decisionMaker: 'Decision Maker',
    decisionInfluencer: 'Decision Influencer',
    technicalExpert: 'Technical Expert',

    headquarters: 'Headquarters',
    distributionCenter: 'Distribution Center',
    manufacturing: 'Manufacturing',
    coldStorage: 'Cold Storage',
    warehouse: 'Warehouse',

    orderStockPicker: 'Order-Stock Picker',
    palletTruck: 'Pallet Truck',
    reachTruck: 'Reach Truck',
    sitDownCounterbalanced: 'Sit-Down Counterbalanced',
    standUpCounterbalanced: 'Stand-Up Counterbalanced',
    towTractor: 'Tow Tractor',
    turretTruck: 'Turret Truck',
    walkieStacker: 'Walkie Stacker',

    caterpillar: 'Caterpillar',
    clark: 'Clark',
    crown: 'Crown',
    heli: 'Heli',
    hyster: 'Hyster',
    hyundai: 'Hyundai',
    jungheinrich: 'Jungheinrich',
    komatsu: 'Komatsu',
    linde: 'Linde',
    mitsubishi: 'Mitsubishi',
    nissan: 'Nissan',
    raymond: 'Raymond',
    tCM: 'TCM',
    toyota: 'Toyota',
    yale: 'Yale',
    advance: 'Advance',
    aGMercury: 'AG Mercury',
    aisleMaster: 'Aisle Master',
    autoGuide: 'AutoGuide',
    barrett: 'Barrett',
    bendiLandoll: 'Bendi/Landoll',
    bigJoe: 'Big Joe',
    blueGiant: 'Blue Giant',
    bTPrimeMover: 'BT Prime Mover',
    dockstocker: 'Dockstocker',
    doosanDaewoo: 'Doosan/Daewoo',
    drexel: 'Drexel',
    elwellParker: 'Elwell-Parker',
    flexi: 'Flexi',
    gregory: 'Gregory',
    hoist: 'Hoist',
    hubtex: 'Hubtex',
    kalmar: 'Kalmar',
    liftALot: 'Lift-a-Lot',
    mariotti: 'Mariotti',
    motrec: 'Motrec',
    multiton: 'Multiton',
    rico: 'Rico',
    royal: 'Royal',
    savantAutomation: 'Savant Automation',
    schaeff: 'Schaeff',
    schreck: 'Schreck',
    sroka: 'Sroka',
    steinbock: 'Steinbock',
    taylorDunn: 'Taylor-Dunn',
    tusk: 'Tusk',
    uniCarrier: 'UniCarrier',
    white: 'White',
    world: 'World',

    batteryBuilders: 'Battery Builders',
    bulldog: 'Bulldog',
    deka: 'Deka',
    enerSys: 'EnerSys',
    gNB: 'GNB',
    hoppecke: 'Hoppecke',

    fastCharge: 'Fast Charge',
    opportunityCharge: 'Opportunity Charge',
    conventionalCharge: 'Conventional Charge',
    change: 'Battery Change',

    fast: 'Fast',
    opportunity: 'Opportunity',
    conventional: 'Conventional',
    opportunityFast: 'Opportunity/Fast',

    aCT: 'ACT',
    aES: 'AES',
    ametek: 'Ametek',
    bassi: 'Bassi',
    crownPowerhouse: 'Crown PowerHouse',
    crownVForce: 'Crown V-Force',
    douglas: 'Douglas',
    ecotec: 'Ecotec',
    enatel: 'Enatel',
    gECI: 'GECI',
    hawker: 'Hawker',
    posicharge: 'Posicharge',
    powerDesigners: 'Power Designers',
    stanbury: 'Stanbury',

    // Battery Builders
    fplm: 'FPLM',

    // Bulldog
    elite_power: 'Elite Power',
    bull_pak: 'Bull-Pak',

    // Crown
    crown_industrial_batteries: 'Crown',
    h2go_low_maintenance: 'H2Go Low Maint.',
    max_capacity: 'Max Capacity',

    // Deka
    d_series: 'D-Series',
    maxpower: 'MaxPowr',
    hydrasaver: 'HydraSaver',
    fastcharge: 'FastCharge',
    dominator: 'Dominator',
    maintenancesaver: 'MaintenanceSaver',
    chargemate: 'Chargemate',
    powrmate: 'PowrMate',
    gel_mate: 'Gel-Mate',
    ready_power_lion: 'Ready Power Li-Ion',

    // Douglas
    legacy_high_performance: 'Legacy High Perf.',
    legacy_opportunity_charge: 'Legacy Opp Charge',
    legacy_rapid_charge: 'Legacy Rapid Charge',
    legacy_low_maintenance: 'Legacy Low Maint.',
    legacy_platinum: 'Legacy Platinum',
    legacy_arctic: 'Legacy Arctic',

    // Enersys
    workhog: 'Workhog',
    deserthog: 'Deserthog',
    loadhog: 'Loadhog',
    superhog: 'Superhog',
    smarthog: 'Smarthog',
    smartpack: 'Smartpack',
    hup: 'HUP',
    nexsys_2v_cells: 'NexSys 2V Cells',
    nexsys_blocks: 'NexSys Blocks',

    // Hawker
    energy_plus: 'Energy-Plus',
    envirlink: 'Envirolink',
    flexpak: 'Flexpak',
    powerline: 'Powerline',
    water_less: 'Water Less',

    // Hoppecke
    uplift: 'Uplift',
    uplift_air: 'Uplift Air',
    uplift_save: 'Uplift Save',

    twoEBU: '2 EBUs / 160%',
    onePointFiveEBU: '1.5 EBUs / 120%',

    floodedLeadAcid: 'Flooded Lead Acid',
    sealedLeadAcid: 'Sealed Lead Acid',
    lithium: 'Lithium',
    leadAcid: 'Lead Acid',
    agm: 'AGM',

    mail: 'mailing',
    shipping: 'shipping',
    billing: 'billing',

    gallonsLPG: 'Gallons of LPG',
    litersLPG: 'Liters of LPG',
    gallonsDiesel: 'Gallons of Diesel',
    litersDiesel: 'Liters of Diesel',
    cubicFeetCNG: '1000 Cubic Feet of CNG',
    cubicMetersCNG: 'Cubic Meters of CNG',

    general: 'General Task',
    installation: 'Installation Task',

    cellular: 'Cellular',
    bluetooth: 'Bluetooth',

    batteryMounted: 'Battery Mounted',
    vehicleMounted: 'Vehicle Mounted',

    batteryChange: 'Battery Change',
    singleBattery: 'Single Battery',
    multipleBatteries: 'Multiple Batteries',

    unknown: 'Unknown',

    ac208Single: '208/240 1ø',
    ac208Three: '208/240 3ø',
    ac480Three: '480 3ø',
    ac600Three: '600 3ø',

    sb175: 'SB 175',
    sb350: 'SB 350',
    rema320: 'Rema 320',
    rema640: 'Rema 640',

    directCustomer: 'Direct Customer',
    batteryDealer: 'Battery Dealer',
    truckDealer: 'Truck Dealer',
    oem: 'OEM',
    thirdPartyLogistics: 'Third Party Logistics',

    // Fuel conversion
    gallonsOfLPG: 'Gallons of LPG',
    gallonsOfDiesel: 'Gallons of Diesel',
    litersOfLPG: 'Liters of LPG',
    litersOfDiesel: 'Liters of Diesel',
    kCubicFeetCNG: '1000 CF of CNG',
    cubicMeterCNG: 'Cubic Meter of CNG',

    gallons: 'Gallons',
    liters: 'Liters',
    kCubicFeet: '1000 CF',
    cubicMeters: 'Cubic Meters',

    less4500: '< 4500 lb.',
    between4500and6499: '4500 - 6499 lb.',
    between6500and7999: '6500 - 7999 lb.',
    greater8000: '> 8000 lb.',

    vehicleWeight: 'Vehicle Weight',

    gal: 'gal',
    litersLower: 'liters',
    kCubicFeetLower: '1000 cu. ft.',
    meters: 'meters',

    perDay: 'Per Day',
    perShift: 'Per Shift',
  },
});
