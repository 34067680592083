import React from "react";
import { hideAlert, logoutUser, setDimensions, setNav } from "../../actions";
import { connect } from "react-redux";
import DynamicAppV2 from "../../shared-components/src/components/DynamicAppV2";
import UserSnap from "../user-snap";

class DynamicAppContainer extends React.Component {
  handleLogoutUser() {
    this.props.logoutUser();
    this.props.setNav({
      index: "login",
      activeNavId: "login",
      activeStepperDepth: 0,
      drawerOpen: this.props.nav.drawerOpen,
    });
    localStorage.removeItem("state");
  }

  render() {
    const { email } = this.props.policyDocument ?? {};
    const { softwareVersion } = this.props.userConfig ?? {};
    const canSendFeedback = /^.+@(motrexllc\.com|stryten\.com)$/.test(
      email ?? ""
    );

    return (
      <>
        <DynamicAppV2
          {...this.props}
          subTitle={this.props.userConfig.appConfig.mainTitle}
          buildIdentifier={process.env.REACT_APP_BUILD_NUMBER || "local"}
          softwareVersion={this.props.userConfig.softwareVersion}
          appConfig={this.props.userConfig.appConfig}
          isProjectsAdmin={false}
          logoutUser={this.handleLogoutUser.bind(this)}
        />
        {canSendFeedback && (
          <UserSnap
            globalApiKey="6f32d02d-0a90-495c-b5af-0bcccc27c9a2"
            initialValues={{ visitor: email }}
            customValues={{ softwareVersion }}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    dimensions: state.dimensions,
    alerts: state.alert,
    nav: state.nav,
    auth: state.authenticate,
    appState: state.appState,
    userConfig: state.userConfig,
    updates: state.updates,
    userConfig: state.userConfig,
    policyDocument: state.policyDocument,
  };
}

export default connect(mapStateToProps, {
  setDimensions,
  setNav,
  hideAlert,
  logoutUser,
})(DynamicAppContainer);
