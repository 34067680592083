import {
  RECEIVED_GET_ACCOUNTS,
  RECEIVED_CREATE_ACCOUNT,
  RECEIVED_UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  RECEIVED_CREATE_ADDRESS,
  RECEIVED_UPDATE_ADDRESS,
  RECEIVED_DELETE_ADDRESS,
  RECEIVED_CREATE_LOCATION,
  RECEIVED_UPDATE_LOCATION,
  RECEIVED_DELETE_LOCATION,
} from '../actions';

import {copyObjectArray} from "../utility";

export default function (state = [], action) {
  let copy = copyObjectArray(state);
  switch (action.type) {
    case RECEIVED_GET_ACCOUNTS:
      return action.payload;

    case RECEIVED_CREATE_ACCOUNT:
      return addAccount(copy, action.payload);

    case RECEIVED_UPDATE_ACCOUNT:
      return updateAccount(copy, action.payload);

    case DELETE_ACCOUNT:
      return removeAccount(copy, action.payload);

    case RECEIVED_CREATE_ADDRESS:
      return createAddress(copy, action.payload);

    case RECEIVED_UPDATE_ADDRESS:
      return updateAddress(copy, action.payload);

    case RECEIVED_DELETE_ADDRESS:
      return deleteAddress(copy, action.payload);

    case RECEIVED_CREATE_LOCATION:
      return createLocation(copy, action.payload);

    case RECEIVED_UPDATE_LOCATION:
      return updateLocation(copy, action.payload);

    case RECEIVED_DELETE_LOCATION:
      return deleteLocation(copy, action.payload);

    default:
      return state;
  }
}

function addAccount(state, account) {
  state.push(account);
  return state;
}

function updateAccount(state, updatedAccount) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === updatedAccount.id) {
      state[i] = {...state[i], ...updatedAccount};
      break;
    }
  }

  return state;
}

function removeAccount(state, id) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === id) {
      state.splice(i, 1);
      break;
    }
  }

  return state;
}

function createAddress(state, address) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === address.account_id) {
      state[i].addresses.push(address);
      break;
    }
  }

  return state;
}

function updateAddress(state, address) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === address.account_id) {
      for(let j = 0;j < state[i].addresses.length; j++) {
        if(state[i].addresses[j].id === address.id) {
          state[i].addresses[j] = address;
        }
      }
      break;
    }
  }

  return state;
}

function deleteAddress(state, address) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === address.account_id) {
      for(let j = 0;j < state[i].addresses.length; j++) {
        if(state[i].addresses[j].id === address.id) {
          state[i].addresses.splice(j, 1);
        }
      }
      break;
    }
  }

  return state;
}

function createLocation(state, location) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === location.account_id) {
      state[i].locations.push(location);
      break;
    }
  }

  return state;
}

function updateLocation(state, location) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === location.account_id) {
      for(let j = 0;j < state[i].locations.length; j++) {
        if(state[i].locations[j].id === location.id) {
          state[i].locations[j] = location;
        }
      }
      break;
    }
  }

  return state;
}

function deleteLocation(state, location) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === location.account_id) {
      for(let j = 0;j < state[i].locations.length; j++) {
        if(state[i].locations[j].id === location.id) {
          state[i].locations.splice(j, 1);
        }
      }
      break;
    }
  }

  return state;
}