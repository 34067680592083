import {take, fork, put, call, spawn, all, select} from 'redux-saga/effects';
import * as actions from '../actions';
import {
  getUsers,
  getAssets,
  getAccounts,
  getActiveAccount,
  getActiveUser,
  getUpdates,
  getPolicyDocument,
  getAuth,
} from "../reducers/selectors";
import {
  api_authenticate,
  api_getUsers,
  api_createUser,
  api_updateUser,
  api_deleteUser,
  api_getGroupDefinitions,
  api_getAssets,
  api_createAsset,
  api_updateAsset,
  api_deleteAsset,
  api_getRoleDefinitions,
  api_createRoleDefinition,
  api_updateRoleDefinition,
  api_deleteRoleDefinition,
  api_getPolicyDefinitions,
  api_getCompartmentFlagDefinitions,
  api_getAccounts,
  api_createAccount,
  api_updateAccount,
  api_deleteAccount,
  api_createLocation,
  api_updateLocation,
  api_deleteLocation,
  api_createAddress,
  api_updateAddress,
  api_deleteAddress,
  api_createContact,
  api_updateContact,
  api_deleteContact,
  api_createGroupDefinition,
  api_updateGroupDefinition,
  api_deleteGroupDefinition,
  api_getUserConfig,
  api_createAccountUser,
  api_deleteAccountUser,
  api_createGroup,
  api_deleteGroup,
  api_getPolicyDocument,
  api_updateUserProfileImage, api_moveAssetList,
} from './api';
import {findObject} from "../utility";
import {copyObject, isEmpty, unflattenList, alphaSortObjectArray, hasNewPolicy} from "../shared-components/src/utility";
import * as appActions from "../actions";


export default function* rootSaga() {
  const sagas = [
    function* watchAndLog() {
      while (true) {
        const action = yield take('*');
        //const state = yield select();

        if (action.type.indexOf('@@redux-form') === -1) {
          console.log('action', action);
        }
      }
    },

    function* watchAuthenticate() {
      while (true) {
        const {payload: auth} = yield take(actions.AUTHENTICATE);
        yield fork(api_authenticate, auth);
      }
    },

    function* watchReceiveAuthenticate() {
      while (true) {
        yield take(actions.RECEIVED_AUTHENTICATE);
        yield put({type: appActions.CLEAR_ALL_UPDATES, payload: {}});
        yield put({type: actions.SET_NAV, payload: {index: 'dashboard', userId: 0, compartmentId: 0, assetId: 0}});
      }
    },

    function* watchSetNav() {
      while (true) {
        const {payload: nav} = yield take(actions.SET_NAV);
        if (nav.index !== 'login') {
          yield call(checkForDataUpdate);
        }
      }
    },

    function* watchGetUsers() {
      while (true) {
        yield take(actions.GET_USERS);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `users`});
        yield fork(api_getUsers);
      }
    },

    function* watchReceivedGetUsers() {
      while (true) {
        yield take(actions.RECEIVED_GET_USERS);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `users`});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchCreateUser() {
      while (true) {
        const {payload: user} = yield take(actions.CREATE_USER);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        const createdUser = yield call(api_createUser, user);
        if (createdUser.id) {
          // Create the initial account_user
          const obj = {
            user_id: createdUser.id,
            account_id: user.initial_account,
          };
          yield call(api_createAccountUser, obj);
          const users = yield select(getUsers);
          const newUser = findObject(users, createdUser.id);
          yield put({type: actions.SET_ACTIVE_USER, payload: newUser});

          // Update the client policy document so this user is in it's list
          yield put({type: actions.GET_POLICY_DOCUMENT});
        }

        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});

        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchUpdateUser() {
      while (true) {
        const {payload: user} = yield take(actions.UPDATE_USER);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateUser, user);
      }
    },

    function* watchReceivedUpdateUser() {
      while (true) {
        const {payload: user} = yield take(actions.RECEIVED_UPDATE_USER);
        yield put({type: actions.SET_ACTIVE_USER, payload: user});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchDeleteUser() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_USER);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteUser, id);
      }
    },

    function* watchReceivedDeleteUser() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_USER);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchGetGroupDefinitions() {
      while (true) {
        yield take(actions.GET_GROUP_DEFINITIONS);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `group-definitions`});
        yield fork(api_getGroupDefinitions);
      }
    },

    function* watchReceivedGetGroupDefinitions() {
      while (true) {
        yield take(actions.RECEIVED_GET_GROUP_DEFINITIONS);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `group-definitions`});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchCreateGroupDefinition() {
      while (true) {
        const {payload: group} = yield take(actions.CREATE_GROUP_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_createGroupDefinition, group);
      }
    },

    function* watchReceivedCreateGroupDefinition() {
      while (true) {
        const {payload: group} = yield take(actions.RECEIVED_CREATE_GROUP_DEFINITION);
        yield put({type: actions.SET_ACTIVE_GROUP_DEFINITION, payload: group});

        // Update the client policy document so this group is in it's list
        yield put({type: actions.GET_POLICY_DOCUMENT});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});

        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchUpdateGroupDefinition() {
      while (true) {
        const {payload: group} = yield take(actions.UPDATE_GROUP_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateGroupDefinition, group);
      }
    },

    function* watchReceivedUpdateGroupDefinition() {
      while (true) {
        const {payload: group} = yield take(actions.RECEIVED_UPDATE_GROUP_DEFINITION);
        yield put({type: actions.SET_ACTIVE_GROUP_DEFINITION, payload: group});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchDeleteGroupDefinition() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_GROUP_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteGroupDefinition, id);
      }
    },

    function* watchReceivedDeleteGroupDefinition() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_GROUP_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchGetAssets() {
      while (true) {
        yield take(actions.GET_ASSETS);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `assets`});
        yield fork(api_getAssets);
      }
    },

    function* watchReceivedGetAssets() {
      while (true) {
        yield take(actions.RECEIVED_GET_ASSETS);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `assets`});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchCreateAsset() {
      while (true) {
        const {payload: asset} = yield take(actions.CREATE_ASSET);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_createAsset, asset);
      }
    },

    function* watchReceivedCreateAsset() {
      while (true) {
        const {payload: asset} = yield take(actions.RECEIVED_CREATE_ASSET);
        yield put({type: actions.SET_ACTIVE_ASSET, payload: asset});

        // Update the client policy document so this Asset is in it's list
        yield put({type: actions.GET_POLICY_DOCUMENT});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});

        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchUpdateAsset() {
      while (true) {
        const {payload: asset} = yield take(actions.UPDATE_ASSET);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateAsset, asset);
      }
    },

    function* watchReceivedUpdateAsset() {
      while (true) {
        const {payload: asset} = yield take(actions.RECEIVED_UPDATE_ASSET);
        yield put({type: actions.SET_ACTIVE_ASSET, payload: asset});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchDeleteAsset() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_ASSET);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteAsset, id);
      }
    },

    function* watchReceivedDeleteAsset() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_ASSET);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchGetRoleDefinitions() {
      while (true) {
        yield take(actions.GET_ROLE_DEFINITIONS);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `roleDefinitions`});
        yield call(api_getRoleDefinitions);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `roleDefinitions`});
      }
    },

    function* watchCreateRoleDefinition() {
      while (true) {
        const {payload: roleDefinition} = yield take(actions.CREATE_ROLE_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_createRoleDefinition, roleDefinition);
      }
    },

    function* watchReceivedCreateRoleDefinition() {
      while (true) {
        const {payload: roleDefinition} = yield take(actions.RECEIVED_CREATE_ROLE_DEFINITION);
        yield put({type: actions.SET_ACTIVE_ROLE_DEFINITION, payload: roleDefinition});

        // Update the client policy document so this role is in it's list
        yield put({type: actions.GET_POLICY_DOCUMENT});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});

        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchUpdateRoleDefinition() {
      while (true) {
        const {payload: roleDefinition} = yield take(actions.UPDATE_ROLE_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateRoleDefinition, roleDefinition);
      }
    },

    function* watchReceivedUpdateRoleDefinition() {
      while (true) {
        const {payload: roleDefinition} = yield take(actions.RECEIVED_UPDATE_ROLE_DEFINITION);
        yield put({type: actions.SET_ACTIVE_ROLE_DEFINITION, payload: roleDefinition});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchDeleteRoleDefinition() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_ROLE_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteRoleDefinition, id);
      }
    },

    function* watchReceivedDeleteRoleDefinition() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_ROLE_DEFINITION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchGetPolicyDefinitions() {
      while (true) {
        yield take(actions.GET_POLICY_DEFINITIONS);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `policyDefinitions`});
        yield call(api_getPolicyDefinitions);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `policyDefinitions`});
      }
    },

    function* watchGetCompartmentFlagDefinitions() {
      while (true) {
        yield take(actions.GET_COMPARTMENT_FLAG_DEFINITIONS);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `compartment-flag-defs`});
        yield call(api_getCompartmentFlagDefinitions);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `compartment-flag-defs`});
      }
    },

    function* watchGetAccounts() {
      while (true) {
        yield take(actions.GET_ACCOUNTS);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `accounts`});
        yield fork(api_getAccounts);
      }
    },

    function* watchReceivedGetAccounts() {
      while (true) {
        yield take(actions.RECEIVED_GET_ACCOUNTS);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `accounts`});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchCreateAccount() {
      while (true) {
        const {payload: account} = yield take(actions.CREATE_ACCOUNT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_createAccount, account);
      }
    },

    function* watchReceivedCreateAccount() {
      while (true) {
        const {payload: account} = yield take(actions.RECEIVED_CREATE_ACCOUNT);
        yield put({type: actions.SET_ACTIVE_ACCOUNT, payload: account});

        // Update the client policy document so this account is in it's list
        yield put({type: actions.GET_POLICY_DOCUMENT});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchUpdateAccount() {
      while (true) {
        const {payload: account} = yield take(actions.UPDATE_ACCOUNT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateAccount, account);
      }
    },

    function* watchReceivedUpdateAccount() {
      while (true) {
        const {payload: account} = yield take(actions.RECEIVED_UPDATE_ACCOUNT);
        yield put({type: actions.SET_ACTIVE_ACCOUNT, payload: account});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchDeleteAccount() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_ACCOUNT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteAccount, id);
      }
    },

    function* watchReceivedDeleteAccount() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_ACCOUNT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchCreateAddress() {
      while (true) {
        const {payload: address} = yield take(actions.CREATE_ADDRESS);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_createAddress, address);
      }
    },

    function* watchReceivedCreateAddress() {
      while (true) {
        const {payload: address} = yield take(actions.RECEIVED_CREATE_ADDRESS);
        yield put({type: actions.SET_ACTIVE_ADDRESS, payload: address});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(updateActiveAccount);
        yield call(checkAccountTreeUpdate);
      }
    },

    function* watchUpdateAddress() {
      while (true) {
        const {payload: address} = yield take(actions.UPDATE_ADDRESS);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateAddress, address);
      }
    },

    function* watchReceivedUpdateAddress() {
      while (true) {
        const {payload: address} = yield take(actions.RECEIVED_UPDATE_ADDRESS);
        yield put({type: actions.SET_ACTIVE_ADDRESS, payload: address});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkAccountTreeUpdate);
        yield call(updateActiveAccount);
      }
    },

    function* watchDeleteAddress() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_ADDRESS);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteAddress, id);
      }
    },

    function* watchReceivedDeleteAddress() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_ADDRESS);
        yield call(checkAccountTreeUpdate);
        yield put({type: actions.SET_ACTIVE_ADDRESS, payload: {}});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(updateActiveAccount);
      }
    },

    function* watchCreateLocation() {
      while (true) {
        const {payload: location} = yield take(actions.CREATE_LOCATION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_createLocation, location);
      }
    },

    function* watchReceivedCreateLocation() {
      while (true) {
        const {payload: location} = yield take(actions.RECEIVED_CREATE_LOCATION);
        yield put({type: actions.SET_ACTIVE_LOCATION, payload: location});
        yield call(updateActiveAccount);
        yield call(checkAccountTreeUpdate);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchUpdateLocation() {
      while (true) {
        const {payload: location} = yield take(actions.UPDATE_LOCATION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateLocation, location);
      }
    },

    function* watchReceivedUpdateLocation() {
      while (true) {
        const {payload: location} = yield take(actions.RECEIVED_UPDATE_LOCATION);
        yield put({type: actions.SET_ACTIVE_LOCATION, payload: location});
        yield call(checkAccountTreeUpdate);
        yield call(updateActiveAccount);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchDeleteLocation() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_LOCATION);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteLocation, id);
      }
    },

    function* watchReceivedDeleteLocation() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_LOCATION);
        yield call(checkAccountTreeUpdate);
        yield call(updateActiveAccount);
        yield put({type: actions.SET_ACTIVE_LOCATION, payload: {}});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchCreateContact() {
      while (true) {
        const {payload: contact} = yield take(actions.CREATE_CONTACT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_createContact, contact);
      }
    },

    function* watchReceivedCreateContact() {
      while (true) {
        const {payload: contact} = yield take(actions.RECEIVED_CREATE_CONTACT);
        yield put({type: actions.SET_ACTIVE_CONTACT, payload: contact});
        yield call(updateActiveAccount);
        yield call(checkAccountTreeUpdate);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchUpdateContact() {
      while (true) {
        const {payload: contact} = yield take(actions.UPDATE_CONTACT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateContact, contact);
      }
    },

    function* watchReceivedUpdateContact() {
      while (true) {
        const {payload: contact} = yield take(actions.RECEIVED_UPDATE_CONTACT);
        yield put({type: actions.SET_ACTIVE_CONTACT, payload: contact});
        yield call(checkAccountTreeUpdate);
        yield call(updateActiveAccount);
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchDeleteContact() {
      while (true) {
        const {payload: id} = yield take(actions.DELETE_CONTACT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_deleteContact, id);
      }
    },

    function* watchReceivedDeleteContact() {
      while (true) {
        yield take(actions.RECEIVED_DELETE_CONTACT);
        yield call(checkAccountTreeUpdate);
        yield call(updateActiveAccount);
        yield put({type: actions.SET_ACTIVE_CONTACT, payload: {}});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchGetUserConfig() {
      while (true) {
        yield take(actions.GET_USER_CONFIG);
        yield put({type: actions.SET_DATA_REQUESTED, payload: `userConfig`});
        yield fork(api_getUserConfig);
      }
    },

    function* watchReceivedGetUserConfig() {
      while (true) {
        yield take(actions.RECEIVED_GET_USER_CONFIG);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `userConfig`});
      }
    },

    function* watchCreateAccountUser() {
      while (true) {
        const {payload: accountUser} = yield take(actions.CREATE_ACCOUNT_USER);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield call(api_createAccountUser, accountUser);

        const activeUser = yield select(getActiveUser);
        if (activeUser.id === accountUser.user_id) {
          const users = yield select(getUsers);
          const user = users.find(u => u.id === accountUser.user_id);
          yield put({type: actions.SET_ACTIVE_USER, payload: user});
          yield call(checkAccountTreeUpdate);
        }
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchDeleteAccountUser() {
      while (true) {
        const {payload: accountUserId} = yield take(actions.DELETE_ACCOUNT_USER);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        const result = yield call(api_deleteAccountUser, accountUserId);

        const activeUser = yield select(getActiveUser);
        if (activeUser.id === result.user_id) {
          const users = yield select(getUsers);
          const user = users.find(u => u.id === result.user_id);
          yield put({type: actions.SET_ACTIVE_USER, payload: user});
          yield call(checkAccountTreeUpdate);
        }
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchCreateGroup() {
      while (true) {
        const {payload: group} = yield take(actions.CREATE_GROUP);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield call(api_createGroup, group);

        const activeUser = yield select(getActiveUser);
        if (activeUser.id === group.user_id) {
          const users = yield select(getUsers);
          const user = users.find(u => u.id === group.user_id);
          yield put({type: actions.SET_ACTIVE_USER, payload: user});
          yield call(checkAccountTreeUpdate);
        }
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchDeleteGroup() {
      while (true) {
        const {payload: groupId} = yield take(actions.DELETE_GROUP);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        const result = yield call(api_deleteGroup, groupId);

        const activeUser = yield select(getActiveUser);
        if (activeUser.id === result.user_id) {
          const users = yield select(getUsers);
          const user = users.find(u => u.id === result.user_id);
          yield put({type: actions.SET_ACTIVE_USER, payload: user});
          yield call(checkAccountTreeUpdate);
        }
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

    function* watchGetPolicyDocument() {
      while (true) {
        yield take(actions.GET_POLICY_DOCUMENT);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield put({type: actions.SET_DATA_REQUESTED, payload: `policyDocument`});
        yield call(api_getPolicyDocument);
        yield put({type: actions.SET_DATA_RECEIVED, payload: `policyDocument`});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        yield call(checkForDataUpdate);
      }
    },
    function* watchUpdateUserProfileImage() {
      while (true) {
        const {payload: {id, image}} = yield take(actions.UPDATE_USER_PROFILE_IMAGE);
        yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
        yield fork(api_updateUserProfileImage, id, image);
      }
    },
    function* watchReceivedUpdateUserProfileImage() {
      while (true) {
        const {payload: user} = yield take(actions.RECEIVED_UPDATE_USER_PROFILE_IMAGE);
        yield put({type: actions.SET_ACTIVE_USER, payload: user});
        yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
      }
    },

      function* watchMoveAssetList() {
        while (true) {
          const {payload: {ids, destinationId}} = yield take(actions.MOVE_ASSET_LIST);
          yield put({type: actions.SET_APP_STATE, payload: {saving: true}});
          yield call(api_moveAssetList, ids, destinationId);
          yield call(updateActiveAccount);
          yield put({type: actions.SET_APP_STATE, payload: {saving: false}});
        }
      }
  ];

  yield all(sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
          } catch (e) {
            console.log(e)
          }
        }
      })
  ));
}

// ********************** Saga Functions ***********************
function* checkForDataUpdate() {
  const updates = yield select(getUpdates),
      policyDocument = yield select(getPolicyDocument),
      auth = yield select(getAuth);

  if(isEmpty(auth)) {
    yield put({type: actions.SET_NAV, payload: {index: 'login'}});
  } else if (!updates.policyDocument || !updates.policyDocument.requested) {
    yield put({type: actions.GET_POLICY_DOCUMENT})
  }

  if (updates.policyDocument && updates.policyDocument.received &&
      hasNewPolicy(policyDocument, 'Portal_CanAccessPortal')) {
    if (!updates.users || !updates.users.requested) {
      yield put({type: actions.GET_USERS});
    }
    if (!updates.assets || !updates.assets.requested) {
      yield put({type: actions.GET_ASSETS});
    }
    if (!updates.roleDefinitions || !updates.roleDefinitions.requested) {
      yield put({type: actions.GET_ROLE_DEFINITIONS});
    }

    if (!updates.policyDefinitions || !updates.policyDefinitions.requested) {
      yield put({type: actions.GET_POLICY_DEFINITIONS});
    }

    if (!updates.accounts || !updates.accounts.requested) {
      yield put({type: actions.GET_ACCOUNTS});
    }

    if (!updates.groupDefinitions || !updates.groupDefinitions.requested) {
      yield put({type: actions.GET_GROUP_DEFINITIONS});
    }


    if (!updates.userConfig || !updates.userConfig.requested) {
      yield put({type: actions.GET_USER_CONFIG});
    }

    if (!updates.policyDocument || !updates.policyDocument.requested) {
      yield put({type: actions.GET_POLICY_DOCUMENT})
    }
  } else if (updates.policyDocument && updates.policyDocument.received) {
    yield put({type: actions.SET_NAV, payload: {index: 'notAuthorized', userId: 0, compartmentId: 0, assetId: 0}});
  }
}

function* checkAccountTreeUpdate() {
  const accounts = yield select(getAccounts);

  if (accounts.length > 0) {
    yield call(createComponentTree);
  }
}

function* createComponentTree() {
  const users = yield select(getUsers),
      accounts = yield select(getAccounts),
      assets = yield select(getAssets);

  let list = copyObject(accounts);

  list.forEach(acc => {
    acc.assets = [];
    acc.users = [];
    acc.total_assets = 0;
    acc.total_users = 0;
    acc.total_accounts = 0;
  });

  let tree = unflattenList(list, 'parent_id', 'name', 'alpha');

  addAccountData(tree[0], users, assets);
  calculateCounts(tree[0]);

  // Set the state
  yield put({type: actions.SET_ACCOUNT_TREE, payload: tree});
}

function addAccountData(node, users, assets) {
  node.users = users.filter(user => {
    let found = false;
    user.account_users.forEach(account_user => {
      if (account_user.account_id === node.id) {
        found = true;
      }
    });
    return found;
  });

  alphaSortObjectArray(node.users, 'last_name');

  node.assets = assets.filter(a => a.account_id === node.id);

  alphaSortObjectArray(node.assets, 'name');

  node.children.forEach(child => {
    addAccountData(child, users, assets);
  });

}

function calculateCounts(node) {
  let counts = {
    assets: node.assets.length,
    users: node.users.length,
    accounts: node.children.length,
  };

  node.children.forEach(child => {
    let cnts = calculateCounts(child);
    counts.assets += cnts.assets;
    counts.users += cnts.users;
    counts.accounts += cnts.accounts;
  });

  node.total_assets = counts.assets;
  node.total_users = counts.users;
  node.total_accounts = counts.accounts;

  return counts;
}


function* updateActiveAccount() {
  const accounts = yield select(getAccounts);
  const activeAccount = yield select(getActiveAccount);

  if (!isEmpty(activeAccount)) {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === activeAccount.id) {
        yield put({type: actions.SET_ACTIVE_ACCOUNT, payload: accounts[i]});
      }
    }
  }
}

