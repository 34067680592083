import {
  RECEIVED_GET_POLICY_DEFINITIONS,
} from '../actions';

const initialState = {
  policyDefinitionsById: {},
  policyDefinitionsMap: [],
  updated: false
};
export default function (state = initialState, action) {

  switch (action.type) {
    case RECEIVED_GET_POLICY_DEFINITIONS:
      return createPolicyDefinitions(action.payload);

    default:
      return state;
  }
}

function createPolicyDefinitions(defs) {
  let obj = {
    policyDefinitionsById: {},
    policyDefinitionsMap: [],
    updated: true
  };

  defs.forEach(def => {
    obj.policyDefinitionsById[def.id] = def;
  });

  return createPolicyDefinitionMap(obj);
}

function createPolicyDefinitionMap(state) {
  delete state.policyDefinitionsMap;
  state.policyDefinitionsMap = {};
  let checkMap = {};


  for (let id in state.policyDefinitionsById) {
    if (state.policyDefinitionsById.hasOwnProperty(id)) {
      let policy = state.policyDefinitionsById[id];
      if (!checkMap[id]) {
        checkMap[id] = [];
      }
      // Add itself to the list
      checkMap[id].push(parseInt(id, 10));

      while (policy.parent_policy_definition_id > 0) {
        checkMap[id].push(policy.parent_policy_definition_id);
        policy = state.policyDefinitionsById[policy.parent_policy_definition_id];
      }
    }
  }

  // Now map all of these back
  for (let id in checkMap) {
    if (checkMap.hasOwnProperty(id)) {
      const check = checkMap[id];

      check.forEach(x => {
        if (!state.policyDefinitionsMap[x]) {
          state.policyDefinitionsMap[x] = [];
        }

        if(!state.policyDefinitionsMap[x].includes(parseInt(id, 10))) {
          state.policyDefinitionsMap[x].push(parseInt(id, 10));
        }
      });
    }
  }

  return state;
}
