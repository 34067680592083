import _ from "lodash";
import UrlPattern from "url-pattern";

export default class urlUtils {

  static getActiveURL(navConfig, nav, map) {
    if (!_.isEmpty(nav)) {
      return this.makeURL(navConfig, nav, map);
    } else {
      const url = window.location.pathname;

      const params = this.getURLParams(navConfig, url);

      return this.makeURL(navConfig, params, map);

    }
  }

  static makeURL(navConfig, nav) {
    // Iterate through each pattern and look for a match
    // to the set of parameters in the map
    let foundRoute = null;
    const paramCount = this.getParamCount(nav);

    navConfig.routes.forEach(route => {
      if (route.index === nav.index && Object.keys(route.params).length === paramCount) {
        let matchCount = this.getParamCount(nav);
        for (let i = 0; i < route.params.length; i++) {
          if (nav[route.params[i]] > 0 || nav[route.params[i]] === 'new') {
            matchCount--;
          }
        }

        if (matchCount === 0) {
          foundRoute = route;
        }
      }
    }, this);

    if (foundRoute) {
      const pattern = new UrlPattern(foundRoute.route);
      let params = {};

      for (let i = 0; i < foundRoute.params.length; i++) {
        params[foundRoute.params[i]] = nav[foundRoute.params[i]];
      }

      return pattern.stringify(params);

    } else {
      return navConfig.fallbackRoute.route;
    }
  }

  static getURLParams(navConfig, url) {
    let ret = {index: navConfig.fallbackRoute.index, drawerOpen: false, lastPath: url};

    navConfig.routes.forEach(route => {
      let pattern = new UrlPattern(route.route);
      let match = pattern.match(url);

      if (match) {
        ret.index = route.index;

        for (let key in match) {
          if (match.hasOwnProperty(key)) {
            if(match[key] === 'new') {
              ret[key] = 'new';
            } else {
              ret[key] = parseInt(match[key],10);
            }
          }
        }
      }
    });

    return ret;
  }

  static getParamCount(nav) {
    let count = 0;
    const valid = ['activeNavId', 'activeStepperDepth', 'drawerOpen', 'index'];
    for (let key in nav) {
      if (nav.hasOwnProperty(key) && !valid.includes(key)) {
        if (nav[key] > 0 || nav[key] === 'new') {
          count++;
        }
      }
    }

    return count;
  }

}