import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import {FlexRow} from '../layouts';
import {strings} from './localization';

const LoginAlert = (props) => {

  const handleLogin = () => {
    props.onLogin();
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
      <div>
        <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={props.open}
            onClose={handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <FlexRow
                  style={{
                    alignContent: 'center',
                  }}
              >
                <FlexRow padTop={3} padLeft={5}>
                  <span id="message-id">{props.msg}</span>
                </FlexRow>
              </FlexRow>

            }
            action={[
              <Button
                  key="close"
                  variant='contained'
                  color='primary'
                  onClick={handleLogin}
              >
                {strings.logIn}
              </Button>,
            ]}
        />
      </div>
  );
};

export default LoginAlert;