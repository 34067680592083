import UrlPattern from 'url-pattern';
import _ from 'lodash';

export function getURLParams(url) {
  let ret = {index: '', userId: 0, compartmentId: 0, assetId: 0, roleId: 0};
  let pattern = new UrlPattern('/users');
  let match = pattern.match(url);

  if (match) {

    ret.index = 'users';
    pattern = new UrlPattern('/users/:id');
    match = pattern.match(url);
    if (match) {
      ret.userId = parseInt(match.id, 10);
    }

    return ret;
  }

  pattern = new UrlPattern('/compartments');
  match = pattern.match(url);

  if (match) {
    ret.index = 'compartments';
    pattern = new UrlPattern('/compartments/:id');
    match = pattern.match(url);
    if (match) {
      ret.compartmentId = parseInt(match.id, 10);
    }

    return ret;
  }

  pattern = new UrlPattern('/assets');
  match = pattern.match(url);

  if (match) {
    ret.index = 'assets';
    pattern = new UrlPattern('/assets/:id');
    match = pattern.match(url);
    if (match) {
      ret.assetId = parseInt(match.id, 10);
    }

    return ret;
  }

  pattern = new UrlPattern('/roles');
  match = pattern.match(url);

  if (match) {
    ret.index = 'roles';
    pattern = new UrlPattern('/role/:id');
    match = pattern.match(url);
    if (match) {
      ret.roleId = parseInt(match.id, 10);
    }

    return ret;
  }

  ret.index = 'dashboard';

  return ret;
}

export function findObject(array, id) {
  return _.find(array, (obj) => {
    return obj.id === parseInt(id, 10);
  });
}

export function copyObject(a) {
  return JSON.parse(JSON.stringify(a));
}

export function copyObjectArray(a) {
  let b = [];

  a.forEach(obj => {
    b.push(copyObject(obj));
  });

  return b;
}

export function compartmentHasChildren(compartments, compartmentId) {
  return _.find(compartments, comp => {
    return comp.parent_id === compartmentId
  });
}

export function compartmentHasUsersOrAssets(users, assets, compartmentId) {
  let ret = false;

  users.forEach(user => {
    if (user.compartment_id === compartmentId) {
      ret = true;
    }
  });

  if (ret) {
    return true;
  }

  assets.forEach(asset => {
    if (asset.compartment_id === compartmentId) {
      ret = true;
    }
  });

  return ret;

}

export function findInTree(node, accountId) {
  if (node.id === accountId) {
    return node;
  } else if (node.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < node.children.length; i++) {
      result = findInTree(node.children[i], accountId);
    }
    return result;
  }
  return null;
}

export function findParentNonOrg(org, arr) {
  if (org && org.parentId) {
    const parent = arr.find(a => a.value === org.parentId);
    if (parent) {
      if (parent.type !== 'org') {
        return parent;
      }

      return findParentNonOrg(parent, arr);
    }
  }

  return null;
}

export function isNotAChild(accounts, accountId, testId) {
  const account = accounts.find(a => a.id === testId);
  if(account) {
    if(account.parent_id === accountId) {
      return false;
    }
    const parent = accounts.find(a => a.id === account.parent_id);
    if(parent) {
      return isNotAChild(accounts, accountId, parent.id);
    }
  }

  return true;
}
export function isValidParent(accounts, accountId, testId) {
  // Should not be itself or a child
  if(accountId === testId) {
    return false;
  }

  return isNotAChild(accounts, accountId, testId);
}

export function onlySelfAndChildren(list, id, parentIdentifier) {
  let arr = [];

  const base = list.find(l => l.id === id);
  arr.push(base);
  return getAllChildren(arr, list, base, parentIdentifier);
}

function getAllChildren(arr, list, parent, parentIdentifier) {
  list.filter(l => l[parentIdentifier] === parent.id).forEach(child => {
    arr.push(child);
    getAllChildren(arr, list, child, parentIdentifier);
  });

  return arr;
}

