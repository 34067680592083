import _ from 'lodash';
import moment from 'moment-timezone';
import {chargingStrategyOptionsShort, facilityTypeOptions} from '../gnb-options';
import {useEffect, useRef, useState} from 'react';

export function copyArray(a) {
  return a.slice();
}

export function copyObject(a) {
  return JSON.parse(JSON.stringify(a));
}

export function copyObjectArray(a) {
  let b = [];

  a.forEach((obj) => {
    b.push(copyObject(obj));
  });

  return b;
}

export function copyObjectToAllStrings(a) {
  return JSON.parse(JSON.stringify(a), (k, v) => {
    //console.log('Key: ', k, ', Type: ', typeof v,', Value: ', v)
    if (typeof v === 'number') {
      return v.toString();
    } else {
      return v;
    }
  });
}

export function momentFromTime(time, addDay = 0) {
  // return moment(moment(`2000-01-01T${time}`).format('YYYY-MM-DDThh:mm')).add(addDay, 'days');
  return moment(`2000-01-01T${time}`).add(addDay, 'days');
}

export function dateFromTime(time) {
  return new Date(`2000-01-01T${time}`);
}

export function getPowerStudyState(beginDate, endDate) {
  if (!beginDate || !endDate) {
    return 'unscheduled';
  } else if (moment(beginDate) < moment() && moment(endDate) > moment()) {
    return 'in_progress';
  } else if (moment(beginDate) > moment()) {
    return 'scheduled';
  } else {
    return 'completed';
  }
}

export function formatNumber(n, suffix, c, d, t) {
  let s = n < 0 ? '-' : '',
      i = parseInt((n = Math.abs(+n || 0).toFixed(c)), 10) + '',
      j = i.length;

  j = j > 3 ? j % 3 : 0;

  c = Math.abs(c);
  c = isNaN(c) ? 2 : c;
  d = d === undefined ? '.' : d;
  t = t === undefined ? ',' : t;

  return (
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
      (c
          ? d +
          Math.abs(n - i).toFixed(c).slice(2)
          : '') +
      suffix
  );
}

export function formatPct(n, c, d, t) {
  n = n * 100;

  let s = n < 0 ? '-' : '',
      i = parseInt((n = Math.abs(+n || 0).toFixed(c)), 10) + '',
      j = i.length;

  j = j > 3 ? j % 3 : 0;
  c = Math.abs(c);
  c = isNaN(c) ? 2 : c;
  d = d === undefined ? '.' : d;
  t = t === undefined ? ',' : t;

  return (
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
      (c
          ? d +
          Math.abs(n - i).toFixed(c).slice(2)
          : '') +
      '%'
  );
}

export function padNumber(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function truncateString(str, length, ending) {
  if (!length) {
    length = 100;
  }
  if (!ending) {
    ending = '...';
  }

  if (str && str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  let context = canvas.getContext('2d');
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width;
}

export function dateRangesOverlap(start1, end1, start2, end2) {
  /*  return (moment(start1) <= moment(start2) && moment(end1) >= moment(start2)) ||
        (moment(start2) <= moment(start1) && moment(end2) >= moment(start1)) ||
        (moment(start1) >= moment(start2) && moment(end1) >= moment(end2)) ||
        (moment(start2) >= moment(start1) && moment(end2) >= moment(end1));*/

  // The 30 second corrections is to help stop trivial overlaps
  return (
      Math.max(moment(start1).add(30, 'seconds'), moment(start2).add(30, 'seconds')) <
      Math.min(moment(end1).subtract(30, 'seconds'), moment(end2).subtract(30, 'seconds'))
  );
}

export function hasPolicy(authObject, policy) {
  let found = false;
  authObject.userInfo.policies.forEach((p) => {
    if (p.name === policy) {
      found = true;
    }
  });

  return found;
}

export function hasNewPolicy(policyDocument, policy, type, id) {
  let found = false;
  if (
      policyDocument &&
      policyDocument.policy_definitions &&
      policyDocument.policy_definitions[policy]
  ) {
    const policyId = policyDocument.policy_definitions[policy];

    policyDocument.groups.forEach((group) => {
      if (!found) {
        if (id && group[type].includes(id)) {
          found = group.policies.includes(policyId);
        } else if (!id) {
          found = group.policies.includes(policyId);
        }
      }
    });
  }

  return found;
}

export function hasOneNewPolicy(policyDocument, policies, type, id) {
  let found = false;
  policies.forEach(policy => {
    if(!found) {
      found = hasNewPolicy(policyDocument, policy, type, id);
    }
  });

  return found;
}

export function findObject(array, id) {
  return _.find(array, (obj) => {
    return obj.id === parseInt(id, 10);
  });
}

export function getMaxOfArray(numArray) {
  return Math.max.apply(null, numArray);
}

export function getMinOfArray(numArray) {
  return Math.min.apply(null, numArray);
}

export function dataLoaded(updates, key) {
  // console.log('Data check for ', key, ' is ', updates[key].received);
  return updates[key].received;
}

export function findInList(find, list) {
  if (!find) {
    return '';
  }
  return _.find(list, (o) => {
    return o.value === find;
  }).label;
}

export function getClient(clientId, clients) {
  let obj = {};
  clients.forEach((client) => {
    if (client.id === clientId) {
      obj = client;
    }
  });
  return obj;
}

export function getProject(projectId, activeClient) {
  let obj = {};
  activeClient.projects.forEach((project) => {
    if (project.id === projectId) {
      obj = project;
    }
  });
  return obj;
}

export function getFacility(facilityId, activeClient) {
  let obj = {};
  activeClient.facilities.forEach((facility) => {
    if (facility.id === facilityId) {
      obj = facility;
    }
  });
  return obj;
}

export function getWorkflow(workflowId, activeProject) {
  // if I had a piece of gold for every time this gets called
  let obj = {};
  activeProject.project_workflows.forEach((workflow) => {
    if (workflow.id === workflowId) {
      obj = workflow;
    }
  });
  return obj;
}

export function getScheduleGroup(scheduleGroupId, workflow) {
  let obj = {
    schedules: [],
  };

  workflow.schedule_groups.forEach((group) => {
    if (group.id === scheduleGroupId) {
      obj = group;
    }
  });

  return obj;
}

export function getVehicle(vehicleId, project) {
  let ret = null;

  project.project_workflows.forEach((wf) => {
    wf.vehicles.forEach((veh) => {
      if (veh.id === vehicleId) {
        ret = veh;
      }
    });
  });

  return ret;
}

export function getLoggerName(loggerId, powerLoggers) {
  let name = '';
  powerLoggers.forEach((logger) => {
    if (logger.id === loggerId) {
      name = logger.name;
    }
  });

  return name;
}

export function getLogger(loggerId, powerLoggers) {
  let ret = {};
  powerLoggers.forEach((logger) => {
    if (logger.id === loggerId) {
      ret = logger;
    }
  });

  return ret;
}

export function getOptionLabel(optionList, value) {
  let ret = '';

  optionList.forEach((option) => {
    if (option.value === value) {
      ret = option.label;
    }
  });

  return ret;
}

export function getFacilityName(facilityList, id) {
  let name = '';
  facilityList.forEach((facility) => {
    if (facility.id === id) {
      name = facility.name;
    }
  });

  return name;
}

export function getFacilityType(facilityList, id) {
  let name = '';
  facilityList.forEach((facility) => {
    if (facility.id === id) {
      name = getOptionLabel(facilityTypeOptions, facility.facility_types[0]);
    }
  });

  return name;
}

export function getFacilityTimeZone(facilityList, id) {
  let timeZone = '';
  facilityList.forEach((facility) => {
    if (facility.id === id) {
      timeZone = facility.time_zone;
    }
  });

  return timeZone;
}

export function getRandomArbitrary(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

export function getYearMonthsFromDecimal(years) {
  const yrs = Math.floor(years);
  const months = Math.ceil((years - yrs) * 12);

  return {years: yrs, months: months};
}

export function isEmpty(obj) {
  /*  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;*/
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export const hasData = (obj) => !isEmpty(obj);

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useStateWithCallback = (initialState, callback) => {
  const [state, setState] = useState(initialState);

  useEffect(() => callback(state), [state, callback]);

  return [state, setState];
};

export function inStringCaseless(stringToTest, stringToCompare) {
  const str = stringToTest.toLowerCase();
  const strTest = stringToCompare.toLowerCase();

  return str.includes(strTest);
}

export function toSnakeCase(string) {
  let s;
  s = string.replace(/[^\w\s]/g, '');
  s = s.replace(/\s+/g, ' ');
  return s.toLowerCase().split(' ').join('_');
}

export function paramStringToObject(paramString, value) {
  const params = paramString.split('.');
  let attr = {};
  let obj = attr;
  if (params.length > 1) {
    for (let i = 0; i < params.length - 1; i++) {
      let para = params[i];
      attr[para] = {};
      attr = attr[para];
    }
  }

  attr[params[params.length - 1]] = value;

  return obj;
}

export function removeFromArray(arr, value) {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function rebaseTreeList(list, id, parentIdentifier) {
  let base = list.find((l) => l.id === id);
  // We need to clear the list of all parents of this node
  let prune = [];
  while (base && base[parentIdentifier]) {
    prune.push(base[parentIdentifier]);

    // eslint-disable-next-line no-loop-func
    base = list.find((l) => l.id === base[parentIdentifier]);
  }

  // Now filter out any danglers
  list.forEach((l) => {
    if (prune.includes(l[parentIdentifier]) && l.id !== id) {
      prune.push(l.id);
    }
  });

  // Filter list by prune array
  return list.filter((l) => !prune.includes(l.id));
}

export function pruneTreeList(list, pruneList, parentIdentifier) {
  let copy = copyObject(pruneList);

  list.forEach((l) => {
    if (copy.includes(l[parentIdentifier])) {
      copy.push(l.id);
    }
  });

  // Filter list by prune array
  return list.filter((l) => !copy.includes(l.id));
}

export function alphaSortObjectArray(array, sortKey) {
  return array.sort((a, b) => {
    let nameA = a[sortKey].toUpperCase(); // ignore upper and lowercase
    let nameB = b[sortKey].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

export function numericSortObjectArray(array, sortKey) {
  return array.sort((a, b) => {
    if (a[sortKey] < b[sortKey]) {
      return -1;
    }
    if (a[sortKey] > b[sortKey]) {
      return 1;
    }
    return 0;
  });
}

export function dateSortObjectArray(array, sortKey) {
  return array.sort((a, b) => {
    if (moment(a[sortKey]).isBefore(moment(b[sortKey]))) {
      return -1;
    }
    if (moment(b[sortKey]).isBefore(moment(a[sortKey]))) {
      return 1;
    }
    return 0;
  });
}

export function unflattenList(list, parentIdentifier, sortKey, sortType) {
  let map = {},
      node,
      roots = [],
      i;
  let tree = [];
  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    tree[i] = Object.assign({}, list[i]);
    tree[i].children = []; // initialize the children
  }
  for (i = 0; i < tree.length; i += 1) {
    node = tree[i];
    if (node[parentIdentifier] !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      if (!tree[map[node[parentIdentifier]]]) {
        roots.push(node);
        sortType === 'alpha'
            ? alphaSortObjectArray(roots, sortKey)
            : numericSortObjectArray(roots, sortKey);
      } else {
        tree[map[node[parentIdentifier]]].children.push(node);

        sortType === 'alpha'
            ? alphaSortObjectArray(tree[map[node[parentIdentifier]]].children, sortKey)
            : numericSortObjectArray(tree[map[node[parentIdentifier]]].children, sortKey);
      }
    } else {
      roots.push(node);
      sortType === 'alpha'
          ? alphaSortObjectArray(roots, sortKey)
          : numericSortObjectArray(roots, sortKey);
    }
  }

  return roots;
}

export function flattenList(tree, childrenKey, arr) {
  if (arr.length === 0) {
    arr.push(tree);
  }
  if (!tree[childrenKey] || tree[childrenKey].length === 0) {
    return;
  }
  for (let i = 0; i < tree[childrenKey].length; i++) {
    let child = tree[childrenKey][i];
    arr.push(child);
    flattenList(child, childrenKey, arr);
  }
}

export function getParentClientOptions(clients) {
  const hlist = unflattenList(clients, 'parent_id', 'name', 'alpha');
  let arr = [];

  addClientChild(arr, hlist, 0);

  return arr;
}

function addClientChild(arr, list, depth) {
  list.forEach((cli) => {
    let name = cli.name.padStart(cli.name.length + depth, '\u2192');

    arr.push({label: name, value: cli.id});

    if (cli.children.length > 0) {
      addClientChild(arr, cli.children, depth + 1);
    }
  });

  return arr;
}

export function isFetching(updates) {
  let fetching = false;

  for (let key in updates) {
    if (updates.hasOwnProperty(key)) {
      if (updates[key].requested === true && updates[key].received === false) {
        fetching = true;
      }
    }
  }

  return fetching;
}

export function getStrategyName(strategy, startRate) {
  if (strategy === 'opportunity_fast') {
    if (startRate >= 0.31) {
      return getOptionLabel(chargingStrategyOptionsShort, 'fast');
    } else {
      return getOptionLabel(chargingStrategyOptionsShort, 'opportunity');
    }
  }

  return getOptionLabel(chargingStrategyOptionsShort, strategy);
}

export function getStrategy(strategy, startRate) {
  if (strategy === 'opportunity_fast') {
    if (startRate >= 0.31) {
      return 'fast';
    } else {
      return 'opportunity';
    }
  } else if (strategy === 'lithium') {
    return 'lithium';
  }

  return strategy;
}

export function findInTree(node, id) {
  if (node.id === id) {
    return node;
  } else if (node.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < node.children.length; i++) {
      result = findInTree(node.children[i], id);
    }
    return result;
  }
  return null;
}

export function fToC(f) {
  return ((f - 32) * 5) / 9;
}

export function cToF(c) {
  return (c * 9) / 5 + 32;
}

export const getDiff = (object, base) => {
  // returns the values of the 'object' parameter
  const changes = (object, base) => {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
            _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  };
  return changes(object, base);
};

// Filters an array to filter out any falsy values or any strings w/ the string of 'null', 'undefined', or 'NaN'
// Joins the array into a single string w/ the passed-in or or default spacer string.
export const addSpacer = (arrayOfStrings, spacerStr) =>
    arrayOfStrings.filter(
        (str) =>
            str &&
            str.length > 0 &&
            Boolean(str) &&
            !str.includes('undefined') &&
            !str.includes('null') &&
            !str.includes('NaN'),
    ).join(spacerStr ? spacerStr : ' | ');

export const getLanguageFromLocale = (locale) => {
  if (!locale) {
    return 'en';
  }

  return locale.substring(0, 2);
};

export const isChildOf = (arr, test, parent, idKey, parentKey) => {
  function findParent(element) {
    const found = arr.find((a) => a[idKey] === element[parentKey]);
    if (!found) {
      return null;
    }
    if (found[idKey] === parent[idKey]) {
      return found;
    }

    return findParent(found);
  }

  if (test[idKey] === parent[idKey]) {
    return true;
  }

  return !!findParent(test);
};

export const isOwnProperty = (object, key) => {
  return Object.prototype.hasOwnProperty.call(object, key);
};
