import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import {FlexRow} from "../layouts";

const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

class Alert extends React.Component {

  handleClick = () => {
    this.setState({open: true});
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.onClose();
  };

  render() {
    const {classes} = this.props;
    return (
        <div>

          <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.props.open}
              autoHideDuration={6000}
              onClose={this.handleClose}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={
                <FlexRow
                     style={{
                       alignContent: 'center'
                     }}
                >

                  {this.props.type === 'error' ?

                      <ErrorIcon
                          color="secondary"
                      />
                      :
                      ''
                  }
                  <FlexRow padTop={3} padLeft={5}>
                    <span id="message-id">{this.props.msg}</span>
                  </FlexRow>
                </FlexRow>

              }
              action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleClose}
                >
                  <CloseIcon/>
                </IconButton>,
              ]}
          />
        </div>
    );
  }
}

export default withStyles(styles)(Alert);