import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './reducer-users';
import activeUserReducer from './reducer-active-user';
import assetReducer  from './reducer-assets';
import activeAssetReducer from './reducer-active-asset';
import authenticateReducer from './reducer-authenticate';
import navReducer from './reducer-nav';
import accountTreeReducer from './reducer-account-tree';
import dimReducer from './reducer-dimensions';
import roleDefinitionsReducer from './reducer-role-definitions';
import policyDefinitionReducer from './reducer-polcy-definitions';
import alertReducer from './reducer-alert';
import activeRoleDefinitionReducer from './reducer-active-role';
import appStateReducer from './reducer-app-state';
import accountReducer from './reducer-accounts';
import activeAccountReducer from './reducer-active-account';
import activeAddressReducer from './reducer-active-address';
import activeLocationReducer from './reducer-active-location';
import activeContactReducer from './reducer-active-contact';
import groupsReducer from './reducer-group-definitions';
import activeGroupReducer from './reducer-active-group-definition';
import userConfigReducer from './reducer-user-config';
import policyDocumentReducer from './reducer-policy-document';
import updatesReducer from './reducer-updates';

import {LOGOUT_USER} from "../actions";

const appReducer = combineReducers({
  form: formReducer,
  users: userReducer,
  activeUser: activeUserReducer,
  assets: assetReducer,
  activeAsset: activeAssetReducer,
  authenticate: authenticateReducer,
  roleDefinitions: roleDefinitionsReducer,
  policyDefinitions: policyDefinitionReducer,
  activeRoleDefinition: activeRoleDefinitionReducer,
  accountTree: accountTreeReducer,
  nav: navReducer,
  dimensions: dimReducer,
  alert: alertReducer,
  appState: appStateReducer,
  accounts: accountReducer,
  activeAccount: activeAccountReducer,
  activeAddress: activeAddressReducer,
  activeLocation: activeLocationReducer,
  activeContact: activeContactReducer,
  groupDefinitions: groupsReducer,
  activeGroupDefinition: activeGroupReducer,
  userConfig: userConfigReducer,
  policyDocument: policyDocumentReducer,
  updates: updatesReducer,
});

const rootReducer = (state, action) => {
  if(action.type === LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
