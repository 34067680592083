import {
  SET_ACTIVE_LOCATION,
} from '../actions';

export default function (state = {}, action) {

  switch(action.type) {
    case SET_ACTIVE_LOCATION:
      return action.payload;

    default:
      return state;
  }
}