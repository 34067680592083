import React from 'react';
import Loader from 'react-loader-spinner';
import {FlexRow} from "../layouts";
import {gnbColorPalette} from '../../gnb-theme';
import {isFetching} from '../../utility';

export default class FetchingIndicator extends React.Component {

  render() {
    const {updates} = this.props;

    let fetching = isFetching(updates);

    if (fetching) {
      return (
          <FlexRow width={100} center>
            <Loader type="ThreeDots" color={gnbColorPalette.dark.secondaryMedium} height={35}/>
          </FlexRow>
      );
    } else {
      return (<div/>);
    }

  }
}
