import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AccountBox from '@material-ui/icons/Person';
import { FlexRow } from '../layouts';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import { gnbColorPalette } from '../../gnb-theme';
import _ from 'lodash';
import { strings } from './localization';
import { withStyles } from '@material-ui/core/styles';

const ProfileMenu = withStyles({
  paper: {
    backgroundColor: gnbColorPalette.dark.level4,
  },
})(Menu);

const Profile = (props) => {
  const { logoutUser, user, isProjectsAdmin, goToSettings, goToAdmin } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  function logout() {
    logoutUser();
    setAnchorEl(null);
  }

  function handleGoToSettings() {
    setAnchorEl(null);
    goToSettings();
  }

  function handleGoToAdmin() {
    setAnchorEl(null);
    goToAdmin();
  }

  function handleClick(event) {
    if (user && user.email) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const initials =
    user.userInfo && !_.isEmpty(user.userInfo)
      ? `${user.userInfo.user.first_name.substring(0, 1)}${user.userInfo.user.last_name.substring(
          0,
          1
        )}`
      : '';
  const PORTAL_URL =
    process.env.REACT_APP_PORTAL_SERVICE ||
    process.env.REACT_APP_PORTAL_URL ||
    'http://localhost:3101';
  const profile_image_url =
    user.userInfo && user.userInfo.user && user.userInfo.user.profile_image_url
      ? `${PORTAL_URL}${user.userInfo.user.profile_image_url}`
      : undefined;

  return (
    <FlexRow>
      <div
        style={{
          marginTop: 10,
          color: 'white',
        }}
      >
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {user.email ? (
            <Avatar
              style={{
                backgroundColor: gnbColorPalette.dark.secondaryMedium,
                color: gnbColorPalette.dark.textLight,
              }}
              src={profile_image_url}
            >
              {!profile_image_url && initials}
            </Avatar>
          ) : (
            <AccountBox style={{ color: 'white' }} />
          )}
        </IconButton>
        <ProfileMenu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem key="logout" onClick={logout}>
            {`${strings.logOut} ${user.email}`}
          </MenuItem>
          {!!goToSettings && (
            <MenuItem key="settings" onClick={handleGoToSettings}>
              {strings.settings}
            </MenuItem>
          )}
          {isProjectsAdmin && (
            <MenuItem key="admin" onClick={handleGoToAdmin}>
              {strings.adminConsole}
            </MenuItem>
          )}
        </ProfileMenu>
      </div>
    </FlexRow>
  );
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  goToSettings: PropTypes.func.isRequired,
  goToAdmin: PropTypes.func.isRequired,
  isProjectsAdmin: PropTypes.bool.isRequired,
};

export default Profile;
