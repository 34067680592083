import {
  SET_ACTIVE_ROLE_DEFINITION
} from '../actions';

export default function (state = {}, action) {

  switch(action.type) {

    case SET_ACTIVE_ROLE_DEFINITION:
      return action.payload;

    default:
      return state;
  }
}