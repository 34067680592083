import {
  RECEIVED_AUTHENTICATE, SET_REMEMBER
} from '../actions';
import jwtDecode from 'jwt-decode';
import {copyObject} from "../utility";


export default function (state = {}, action) {

  switch (action.type) {
    case RECEIVED_AUTHENTICATE: {
      let info = createInfo(action.payload);
      let authenticate = copyObject(state);
      authenticate.userInfo = info.userInfo;
      authenticate.email = info.email;
      authenticate.auth_token = info.auth_token;
      return authenticate;
    }

    case SET_REMEMBER: {
      let x = copyObject(state);
      x.remember = action.payload
      return x;
    }

    default:
      return state;
  }
}

function createInfo(data) {
  let copy = copyObject(data);

  copy.userInfo = jwtDecode(data.auth_token);
  return copy;
}