import {
  RECEIVED_GET_USERS,
  RECEIVED_CREATE_USER,
  RECEIVED_UPDATE_USER,
  DELETE_USER,
  RECEIVED_CREATE_ACCOUNT_USER,
  RECEIVED_DELETE_ACCOUNT_USER,
  RECEIVED_CREATE_GROUP,
  RECEIVED_DELETE_GROUP,
  RECEIVED_UPDATE_USER_PROFILE_IMAGE,
} from '../actions';

import {copyObjectArray} from "../utility";

export default function (state = [], action) {
  let copy = copyObjectArray(state);
  switch (action.type) {
    case RECEIVED_GET_USERS:
      return action.payload;

    case RECEIVED_CREATE_USER:
      return addUser(copy, action.payload);

    case RECEIVED_UPDATE_USER:
    case RECEIVED_UPDATE_USER_PROFILE_IMAGE:
      return updateUser(copy, action.payload);

    case DELETE_USER:
      return removeUser(copy, action.payload);

    case RECEIVED_CREATE_ACCOUNT_USER:
      return addAccountUser(copy, action.payload);

    case RECEIVED_DELETE_ACCOUNT_USER:
      return removeAccountUser(copy, action.payload);

    case RECEIVED_CREATE_GROUP:
      return addGroup(copy, action.payload);

    case RECEIVED_DELETE_GROUP:
      return removeGroup(copy, action.payload);

    default:
      return state;
  }
}

function addUser(state, user) {
  state.push(user);
  return state;
}

function updateUser(state, updatedUser) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === updatedUser.id) {
      state[i] = updatedUser;
      break;
    }
  }

  return state;
}

function removeUser(state, id) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === id) {
      state.splice(i, 1);
      break;
    }
  }

  return state;
}

function addAccountUser(state, accountUser) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === accountUser.user_id) {
      state[i].account_users.push(accountUser);
      break;
    }
  }

  return state;
}

function removeAccountUser(state, accountUser) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === accountUser.user_id) {
      for (let j = 0; j < state[i].account_users.length; j++) {
        if (state[i].account_users[j].id === accountUser.id) {
          state[i].account_users.splice(j, 1);
          break;
        }
      }
    }
  }

  return state;
}

function addGroup(state, group) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === group.user_id) {
      state[i].groups.push(group);
      break;
    }
  }

  return state;
}

function removeGroup(state, group) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === group.user_id) {
      for (let j = 0; j < state[i].groups.length; j++) {
        if (state[i].groups[j].id === group.id) {
          state[i].groups.splice(j, 1);
          break;
        }
      }
    }
  }

  return state;
}