import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    // Main
    mainTitle: 'Portal',
    loggedInAs: 'Logged in as',
    copyrightText: "\u00A9 2018 3D Fleet is a registered trademark of Aker Wade Power Technologies",

    // General
    loading: 'Loading...',

    // Buttons
    logout: 'Logout',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',

    // Navigation
    dashboard: 'Dashboard',
    users: 'Users',
    compartments: 'Compartments',
    assets: 'Assets',
    roles: 'Roles',
    groups: 'Groups',

    // Dashboard
    detailView: 'Show Detail View',
    showAppCompartments: 'Show Application Compartments',

    // Users
    monitorLocation: 'Monitor Location',
    powerStudy: 'Power Study',
    signInCount: 'Sign In Count',
    lastLoginDate: 'Last Login Date',
    lastLoginIP: 'Last Login IP',

    allowDrag: 'Allow Dragging',

    newAccount: 'New Account',
    inherit: 'Inherit from Parent',
    accounts: 'Accounts',
    appMetaData: 'App Meta Data',

    address: 'Address',
    deleteAccount: 'Delete Account',
    returnTo: 'Return To',

    mail: 'Mail',
    shipping: 'Shipping',
    billing: 'Billing',

    addresses: 'Addresses',
    addressName: 'Address Name',
    addressType: 'Address Type',
    country: 'Country',
    state: 'State',
    city: 'City',
    longitude: 'Longitude',
    latitude: 'Latitude',
    street1: 'Street 1',
    street2: 'Street 2',
    street3: 'Street 3',
    postalCode: 'Postal Code',
    timeZone: 'Time Zone',

    loginFailed: 'Your login credentials are not valid',
    loginRequired: 'Your login has expired, please login again',
    serverError: 'There was a server error on your last request',

    location: 'Location',
    locations: 'Locations',
    locationName: 'Location Name',
    locationDescription: 'Location Description',
    locationType: 'Location Type',

    contact: 'Contact',
    contacts: 'Contacts',
    salutation: 'Salutation',
    name: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    middleName: 'Middle Name',
    suffix: 'Suffix',
    title: 'Title',
    officeEmail: 'Office Email',
    personalEmail: 'Personal Email',
    officePhone: 'Office Phone',
    personalPhone: 'Personal Phone',
    officeFax: 'Office Fax',
    officeAddress: 'Office Address',
    reportsTo: 'Reports To',

    accountRoot: 'Account Root',

    dashboard2: 'New Dashboard',

    uploadProfileImage: 'Upload Profile Image',

    notAuthorized: 'Not Authorized',
  }
});