import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import LoginAlert from './LoginAlert';
import {strings} from './localization';

const ExpirationCheck = (props) => {
  const { authenticate} = props;

  const [open, setOpen] = useState(false);
  const [loginMessage, setLoginMessage] = useState(strings.yourSessionIsEnding);

  useEffect(() => {
    const interval = setInterval(() => {
      if(authenticate && authenticate.userInfo && authenticate.userInfo.exp) {
        const diff = moment.unix(authenticate.userInfo.exp).diff(moment(), 'minutes')
        if(diff > 0 && diff <= 15 && !open) {
          setOpen(true);
          setLoginMessage(strings.yourSessionIsEnding);
        } else if(diff <= 0 && !open) {
          setOpen(true);
          setLoginMessage(strings.yourSessionHasExpired);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  });

  function handleClose() {
    setOpen(false);
  }

  function handleLogin() {
    setOpen(false);
    props.onLogin();
  }

  return (
      <LoginAlert
          open={open}
          msg={loginMessage}
          type='warn'
          onClose={handleClose}
          onLogin={handleLogin}
      />
  )
};

export default ExpirationCheck;