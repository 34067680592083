import {
  SET_ACTIVE_ADDRESS,
} from '../actions';

export default function (state = {}, action) {

  switch(action.type) {
    case SET_ACTIVE_ADDRESS:
      return action.payload;

    default:
      return state;
  }
}